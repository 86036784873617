// Filename - pages/contact.js
import '../style.css';
import ProductCard from "../../components/ProductCard/ProductCard";
import { fetchProducts } from '../../services/ProductService';
import React, { useState } from 'react';
import { useEffect } from "react";
import { useStore } from '../../store/store';

const Product = () => {
	const [products, setProducts] = useState([]);
	
    const walletStatus = useStore((state) => state.walletStatus);
    const updateEtherPrice = useStore((state) => state.setEtherPrice);
	useEffect(() => {
		const fetchData = async () => {
		  const productsData = await fetchProducts();
		  // console.log(productsData.data);
		  setProducts(productsData.data);
		  updateEtherPrice(3563.77);
		};
		fetchData();
		// console.log(products)
	}, []);
	
	return (
		<section>
			<div className="container">
				<div className="row">
					<div className="col-sm-12">
						<h1>
							Our Products
						</h1>
					</div>
				</div>
				<div className="row">
					{ 
						products.length === 0 ? 
						(
							<p>No products found</p>
						) : 
						(
							products.map((product, index) => (
								<div className='col-sm-3 p-4'  key={product.id}>
									<ProductCard product={product} isActive={walletStatus} isOrder={false}  />
								</div>
							))
						)
					}
				</div>

			
				
			</div>
		</section>
	);
};

export default Product;
