import React, { useState} from "react";
import "./NewsletterHome.css";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";

const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const NewsletterHome = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!validateEmail(email)) {
        toast.error('Please enter a valid email address.');
        return;
      }
      
      if (!email) {
        setLoading(false); // Hide loader
        toast.error("Email  are required."); // Set an error message
        return; // Stop the form submission
      }
      setLoading(true); // Show loader
      try {
        const response = await fetch(APP_BASE_URL +'/subscribe', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
           // Authorization: "Bearer " + localStorage.getItem("token"),
          },
          
          body: JSON.stringify({ email }),
        });
       
        const data = await response.json();
       
        if (data.success) {
          setEmail('');
            toast.success("Email subscribed successfully!");
        }
        if (!data.success) {
          toast.error(data.message);
        }
       
      } catch (error) {
        console.log(error);
        toast.error('Error ', error)
       
      }finally {
        setLoading(false); 
      }
    };

    const validateEmail = (email) => {
      if (!email) {
        return false;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      
      if (!emailRegex.test(email)) {
        return false;
      }
      const localPart = email.split('@')[0];
  
      if (email === 'example@example.com' || localPart.includes('example')) {
        return false;
      }
  
      return true;
    };
    return (
        <>
        {
            (loading)
            ? 
             <Loader />
           :
           null
       }
       <section className='newsletterHomeSec'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='secTitle'>
                            <h1>News Letter!</h1>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='secTitle'>
                            <p>Stay updated with our latest news, offers, and developments by subscribing to our newsletter. Join our community today!</p>
                        </div>
                        <div className='newsletterForm'>
                            <form onSubmit={handleSubmit}>
                            <div className='newsletterFields'>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter your email"
                                    
                                />
                               <button className='SubscribeBtn'>Subscribe now</button>
                               </div>
                            </form>
                            
                        </div>
                    </div>
                </div>
            </div>
       </section>
       </>
    );
};

export default NewsletterHome;