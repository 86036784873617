
import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { useStore } from "../../store/store";
// import WalletConnect from "../WalletConnect";
import WalletPhantom from "../WalletPhantom";

import headerLogo from "../../images/Logo-Final.webp";
import Dropdown from 'react-bootstrap/Dropdown';
import accounticon  from "../../images/account-icon.webp";
import Logout from "../../components/Logout/Logout";
import LogoutTimer from "../../components/Logout/LogoutTimer";
import { toast } from "react-toastify";
const Header = () => {
    const navigate = useNavigate();
    const updateWalletStatus = useStore((state) => state.setWalletStatus);
    const updateDefaultAccount = useStore((state) => state.setDefaultAccount);
    const updateNavFlag = useStore((state) => state.setNavFlag);
    const logout = () => {
          // Disconnect Wallet
          updateWalletStatus(null);
          updateDefaultAccount(null);
          updateNavFlag(null);
            toast.error('User has been logged out due to inactivity');
        // Implement your logout logic here
        localStorage.clear();
        navigate("/account");
        // Example: redirecting to logout endpoint or clearing session
      };
      
    
    // useEffect(() => {
    //     setName(localStorage.getItem("name"));
    //   }, []);
	return (
		<>
       <LogoutTimer logout={logout} />

			<header className="siteHeader">
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                <Link to="/home"><img src={headerLogo} alt="Header Logo"/></Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="rightNav sgNav">
                            <Link to="/home">Home</Link>
                            <Link
                                to="/home"
                                onClick={() => {
                                    setTimeout(() => {
                                    const productsSection = document.getElementById("products");
                                    if (productsSection) {
                                        window.scrollTo({
                                        top: productsSection.offsetTop,
                                        behavior: "smooth"
                                        });
                                    }
                                    }, 100); // Adjust the delay as needed to ensure correct scrolling
                                }}
                                >
                                Products
                            </Link>
                            <Link
                                to="/home"
                                onClick={() => {
                                    setTimeout(() => {
                                    const productsSection = document.getElementById("SevenLevels");
                                    if (productsSection) {
                                        window.scrollTo({
                                        top: productsSection.offsetTop,
                                        behavior: "smooth"
                                        });
                                    }
                                    }, 100); // Adjust the delay as needed to ensure correct scrolling
                                }}
                                >
                                Referral Program
                            </Link>
                             <Link
                                to="/home"
                                onClick={() => {
                                    setTimeout(() => {
                                    const productsSection = document.getElementById("HowItWorks");
                                    if (productsSection) {
                                        window.scrollTo({
                                        top: productsSection.offsetTop,
                                        behavior: "smooth"
                                        });
                                    }
                                    }, 100); // Adjust the delay as needed to ensure correct scrolling
                                }}
                                >
                                Who We Are
                            </Link>

                            <Link
                             to="/home"
                             onClick={() => {
                                 setTimeout(() => {
                                 const roadmapSection = document.getElementById("roadmap");
                                 if (roadmapSection) {
                                     window.scrollTo({
                                     top: roadmapSection.offsetTop,
                                     behavior: "smooth"
                                     });
                                 }
                                 }, 100); // Adjust the delay as needed to ensure correct scrolling
                             }}
                             >
                             Roadmap
                             </Link>
                            <Link
                             to="/home"
                             onClick={() => {
                                 setTimeout(() => {
                                 const buysellSection = document.getElementById("OurTeam");
                                 if (buysellSection) {
                                     window.scrollTo({
                                     top: buysellSection.offsetTop,
                                     behavior: "smooth"
                                     });
                                 }
                                 }, 100); // Adjust the delay as needed to ensure correct scrolling
                             }}
                             >
                            Our team
                            </Link>
                            <div className="RightMenu">
                            <Link to="/works">user Guide</Link> {/* Regular Link */}

                            
                            {
                                    ((localStorage.getItem("token"))) 
                                    ?
                                    <>
                                       <Dropdown>
                                            <Dropdown.Toggle  id="dropdown-basic">
                                            <img src={accounticon} alt='Account Icon' />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                            <Link to="/my_account">DashBoard </Link>
                                                <Logout/>
                                            </Dropdown.Menu>
                                       </Dropdown>
                                    </>
                                    :
                                    <>
                                        <Link to="/account">Login</Link>
                                        
                                    </>
                            }
                                
                            

                            {
                                (( localStorage.getItem("type") === '0')) 
                                ? 
                                    <>
                                        <Link to="/completedOrders">C.Orders</Link>
                                    </>
                                :
                                    null
                            }
                            </div>
                            <Link
                            to="#" className="headerBtn"
                            onClick={(e) => {
                                e.preventDefault();
                                const buysellSection = document.getElementById("link");
                                if (buysellSection) {
                                    buysellSection.scrollIntoView({ behavior: "smooth" });
                                }
                            }}
                        >
                            {/* <WalletPhantom /> */}
                            </Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            </header>
		</>
	);
};

export default Header;
