/* eslint-disable jsx-a11y/anchor-is-valid */

import "../login/login.css";
import "../profile/profile.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaUserEdit } from 'react-icons/fa';

import { useStore } from "../../store/store";
import { toast } from "react-toastify";
import Header from "../../components/Header/Header";
import LoginSidebar from "../../components/LoginSidebar/LoginSidebar";
import Logout from "../../components/Logout/Logout";
import NewsletterHome from "../../components/NewsletterHome/NewsletterHome";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";
import InnerBanner from "../../images/inner-banner.webp";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import editicon  from "../../images/tabler_edit.webp";


const APP_BASE_URL = process.env.REACT_APP_BASE_URL;

function MyAccount() {

  const navigate = useNavigate();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNo, setPhoneNo] = useState(null);
  const [gender, setGender] = useState(null);
  const [dob, setDob] = useState(null);
  const [name, setName] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [userId, setUserId] = useState(null);
  

 
  useEffect(() => {
    if(!localStorage.getItem("token")){
      navigate("/account");
    }
    
    setName(localStorage.getItem("name"));
    setType(localStorage.getItem('type'));
    setFirstName(localStorage.getItem("firstname"));
    setLastName(localStorage.getItem("lastname"));
    setEmail(localStorage.getItem("email"));
    setPhoneNo(localStorage.getItem("phone_no"));
    setDob(localStorage.getItem("dob"));
    setGender(localStorage.getItem("gender"));
    setUserId(localStorage.getItem("userId"));
    setProfilePic(localStorage.getItem("profilePic"));
   
   
  }, []);



  const syncDetail = (data) => {
    localStorage.setItem("name", data.name);
    localStorage.setItem('type', data.type);
    localStorage.setItem("firstname", data.firstname);
    localStorage.setItem("lastname", data.lastname);
    localStorage.setItem("email", data.email);
    localStorage.setItem("phone_no", data.phone_no);
    localStorage.setItem("dob", data.dob);
    localStorage.setItem("gender", data.gender);
    localStorage.setItem("userId", data.id);
    localStorage.setItem("profilePic", data.profile_pic);

    setName(data.name);
    setType(data.type);
    setFirstName(data.firstname);
    setLastName(data.lastname);
    setEmail(data.email);
    setPhoneNo(data.phone_no);
    setDob(data.dob);
    setGender(data.gender);
    setUserId(data.id);
    setProfilePic(data.profile_pic);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const localPart = email.split('@')[0];

    if (!emailRegex.test(email)) {
      return false;
    }

    if (email === 'example@example.com' || localPart.includes('example')) {
      return false;
    }

    return true;
  };

  const handleProfile = async (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }
    setLoading(true); // Show loader
    try {
      // console.log("email", email);
      // console.log("password", password);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          id: userId,
          firstname: firstName,
          lastname: lastName,
          email: email,
          phone_no: phoneNo,
          dob: dob,
          gender: gender,
        }),
      };
      
      const response = await fetch(APP_BASE_URL + "/updateProfile", requestOptions);
      
      const data = await response.json();
      // console.log('Response:', data);
      if (data.success) {
        // Assuming data contains the token and name

        syncDetail(data.data);
        
        toast.success("Profile updated successfully");
      } else {
        if (data.data.error) {
          toast.error(data.data.error);
        } else {
          let allErrors = "";
          for (const property in data.data) {
            allErrors = " " + data.data[property] + ".";
            // console.log(`${property}: ${data.data[property]}`);
          }
          toast.error(allErrors);
        }
      }

      // Redirect or update state to indicate successful login
    } catch (e) {
      
      toast.error("Error:", e);
      // Handle error, e.g., display an error message to the user
    } finally {
      setLoading(false); 
    }
  };
  const minAllowedDate = new Date();
    minAllowedDate.setFullYear(minAllowedDate.getFullYear() - 18);
    const minDateStr = minAllowedDate.toISOString().split('T')[0];
  return (
    <>
      <Header />
        <>
        {
           (loading)
           ? 
            <Loader />
          :
          null
        }
        <div className="innerPageBanner">
          <img src={InnerBanner} alt="Inner Page Banner" />
          <h2>Profile</h2>
        </div>
        <section className="profileSec">
          <Container>
            <Row>
              <Col sm={3} className="column-three">
             
                <LoginSidebar Userprofile={profilePic} Profilename={name} id={userId}/>
                <Logout/>
              </Col>
              <Col sm={9} className="column-nine">
                <div className="tabTitle">
                  <h4>Personal Information</h4>
                </div>
                <div className="sgProfile">

                    <div className="iconWrapper">
                        <img src={editicon} onClick={() => setIsFormOpen(!isFormOpen)} style={{ cursor: 'pointer', fontSize: '24px' }} />
                    </div>
                    {!isFormOpen && (
                    <div className="personalForm">
                      <div className="sgLabel">
                      <label>Name</label>
                      <div className="labelFileds">
                        {firstName} {lastName}
                      </div>
                      </div>
                      <div className="sgLabel">
                      <label>Email</label>
                      <div className="labelFileds">
                        {email}
                      </div>
                      </div>
                      <div className="sgLabel">
                      <label>Phone number</label>
                      <div className="labelFileds">
                        {phoneNo}
                      </div>
                      </div>
                      <div className="sgLabel">
                      <label>Date of birth</label>
                      <div className="labelFileds">
                        {dob}
                      </div>
                      </div>
                      <div className="sgLabel">
                      <label>Gender</label>
                      <div className="labelFileds">
                        {gender}
                      </div>
                      </div>
                    </div>
                    )}
</div>
            {isFormOpen && (
                <div className="personalInfoForm">
                  <form onSubmit={handleProfile}>
                    <div className="personalForm">
                      <label>Name</label>
                      <div className="halfColRow">
                        <div className="halfCol">
                          <input
                            type="text"
                            placeholder="First Name"
                            value={firstName}
                            onChange={(e) =>
                              setFirstName(e.target.value)
                            }
                          />
                        </div>
                        <div className="halfCol">
                          <input
                            type="text"
                            value={lastName}
                            onChange={(e) =>
                              setLastName(e.target.value)
                            }
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="personalForm">
                      <label>Email</label>
                      <input
                        type="email"
                        placeholder="info@gmail.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled
                      />
                    </div>
                    <div className="personalForm">
                      <label>Phone number</label>
                      <input
                        type="text"
                        placeholder="+1 123 456 7890"
                        value={phoneNo}
                        onChange={(e) => setPhoneNo(e.target.value)}
                      />
                    </div>
                    <div className="personalForm">
                      <label>Date of birth</label>
                      <input
                        type="date"
                        placeholder="00/00/0000"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        max={minDateStr}
                      />
                    </div>
                    <div className="personalForm">
                      <label>Gender</label>
                      <div className="halfColRow">
                        <div className="radioButton">
                          <input
                            id="male"
                            type="radio"
                            value="Male"
                            checked={gender === "Male"}
                            onChange={(e) => setGender(e.target.value)}
                            name="radiobtn"
                          />
                          <label htmlFor="male">
                            <span className="form__radio-button"></span>
                            <span className="buttonLable">Male</span>
                          </label>
                        </div>
                        <div className="radioButton">
                          <input
                            id="female"
                            type="radio"
                            value="Female"
                            onChange={(e) => setGender(e.target.value)}
                            checked={gender === "Female"}
                            name="radiobtn"
                          />
                          <label htmlFor="female">
                            <span className="form__radio-button"></span>
                            <span className="buttonLable">Female</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="connectBtn">
                      Save
                    </button>
                  </form>
                </div>
                 )}
              </Col>
            </Row>
          </Container>
        </section>
        </>
      <NewsletterHome />
      <Footer />
    </>
  );
}

export default MyAccount;
