// Filename - pages/index.js

import React from "react";

import Header from "../components/Header/Header";
import HomeBanner from "../components/HomeBanner/HomeBanner";
import OurProducts from "../components/OurProducts/OurProducts";
import RoadMap from "../components/RoadMap/RoadMap";
import BuySell from "../components/BuySell/BuySell";
import HowItWorks from "../components/HowItWorks/HowItWorks";
import OurTeam from "../components/OurTeam/OurTeam";
import MoneyBack from "../components/MoneyBack/MoneyBack";
import GameKingdom from "../components/GameKingdom/GameKingdom";
import NewsletterHome from "../components/NewsletterHome/NewsletterHome";
import SevenLevels from "../components/SevenLevels/SevenLevels";
import ReferralUsers from "../components/ReferralUsers/ReferralUsers";
import Footer from "../components/Footer/Footer";

const Home = () => {
	return (
		<>
			<Header/>
			<HomeBanner/>
			<OurProducts/>
			<SevenLevels/>
			<ReferralUsers/>
			<HowItWorks/>
			<RoadMap/>
			<OurTeam/>
			<MoneyBack/>	
			<GameKingdom/>
			<NewsletterHome/>
			<Footer/>
		</>
	);
};

export default Home;
