/* eslint-disable jsx-a11y/anchor-is-valid */

import "../login/login.css";
import "./profile.css";
import React, { useState, useRef, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store/store";
import { toast } from "react-toastify";
import Login from "../login/login";
import Header from "../../components/Header/Header";
import Logout from "../../components/Logout/Logout";
import LoginSidebar from "../../components/LoginSidebar/LoginSidebar";
import NewsletterHome from "../../components/NewsletterHome/NewsletterHome";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";
import InnerBanner from "../../images/inner-banner.webp";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import ClipboardImg from "../../images/clipboard-img.webp";
import {
  fetchActiveOrders,
  fetchCompletedOrders,
} from "../../services/OrderService";
import prod1  from "../../images/pro-small-1.png";
import prod2  from "../../images/pro-small-2.png";
import prod3  from "../../images/pro-small-3.png";
const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const SOLCAN_URL = process.env.REACT_APP_SOLCAN_URL;
const SOLCAN_CLUSTER = process.env.REACT_APP_SOLCAN_CLUSTER;

function Kingdoms() {
    const navigate = useNavigate();
    
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNo, setPhoneNo] = useState(null);
  const [gender, setGender] = useState(null);
  const [dob, setDob] = useState(null);
  const [oldPassword, setOldPassword] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState(null);
  const [name, setName] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [userId, setUserId] = useState(null);
  const [path, setPath] = useState(true);
  const [orders, setOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const fetchInitiated = useRef(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('home');
//   const walletStatus = useStore((state) => state.walletStatus);
 
//   const updateWalletStatus = useStore((state) => state.setWalletStatus);

//   const defaultAccount = useStore((state) => state.defaultAccount);
//   const updateDefaultAccount = useStore((state) => state.setDefaultAccount);
//   const navFlag = useStore((state) => state.navFlag);
//   //alert(navFlag);
//   const updateNavFlag = useStore((state) => state.setNavFlag);

  useEffect(() => {
    if(!localStorage.getItem("token")){
        navigate("/account");
      }
    setName(localStorage.getItem("name"));
    setType(localStorage.getItem('type'));
    setFirstName(localStorage.getItem("firstname"));
    setLastName(localStorage.getItem("lastname"));
    setEmail(localStorage.getItem("email"));
    setPhoneNo(localStorage.getItem("phone_no"));
    setDob(localStorage.getItem("dob"));
    setGender(localStorage.getItem("gender"));
    setUserId(localStorage.getItem("userId"));
    setProfilePic(localStorage.getItem("profilePic"));

    const fetchData = async () => {
       
        setError(null); // Reset error before fetching data
      const orderData = await fetchActiveOrders();
      setOrders(orderData.data);

      const completedOrderData = await fetchCompletedOrders();
      setCompletedOrders(completedOrderData.data);
      //if (completedOrderData.data.some(order => order.req_payment === 1)) {
       // setActiveTab('profile');
      //}
      setLoading(false);
    };
    if (!fetchInitiated.current && localStorage.getItem("name")) {
        fetchInitiated.current = true;
        setLoading(true);
        fetchData();
      }
  }, []);



//   const handleLogout = async () => {
//     // console.log(localStorage.getItem('token'))
//     try {
//       const requestOptions = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: "Bearer " + localStorage.getItem("token"),
//         },
//       };

//       const response = await fetch(APP_BASE_URL + "/logout", requestOptions);
//       const data = await response.json();
//       // console.log('Response:', data);
//       unSyncDetail();

//       // Disconnect Wallet
//       updateWalletStatus(null);
//       updateDefaultAccount(null);
//       updateNavFlag(null);
//       toast.success("Logged out successfully");
//       // Redirect or update state to indicate successful login
//     } catch (error) {
//       toast.error("Error:", error);
//       unSyncDetail();
//       // Handle error, e.g., display an error message to the user
//     }
//   };
    const handleSellClick = async (userId, orderId) => {
        setLoading(true);
        try {

        const requestOptions = {
                 method: "POST",
                 headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: JSON.stringify({
                    id: orderId,
                    userid: userId
                    
                }),
        };
        
        const response = await fetch(APP_BASE_URL + "/updateReqPaymentStatus", requestOptions);
        const data = await response.json();
       
        if(data.success){
           
           
            window.location.reload();
            //toast.success(data.message);
           // setActiveTab('profile');
        }
       
        } catch (error) {
            toast.error("Error:", error);
        }finally {
            setLoading(false); 
         }
    };
  const [copied, setCopied] = useState(false); // State to track if the referral code is copied

  const copyReferralCode = (code) => {
    navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
    alert("Referral code copied to clipboard!");
  };

  const syncDetail = (data) => {
    localStorage.setItem("name", data.name);
    localStorage.setItem('type', data.type);
    localStorage.setItem("firstname", data.firstname);
    localStorage.setItem("lastname", data.lastname);
    localStorage.setItem("email", data.email);
    localStorage.setItem("phone_no", data.phone_no);
    localStorage.setItem("dob", data.dob);
    localStorage.setItem("gender", data.gender);
    localStorage.setItem("userId", data.id);
    localStorage.setItem("profilePic", data.profile_pic);

    setName(data.name);
    setType(data.type);
    setFirstName(data.firstname);
    setLastName(data.lastname);
    setEmail(data.email);
    setPhoneNo(data.phone_no);
    setDob(data.dob);
    setGender(data.gender);
    setUserId(data.id);
    setProfilePic(data.profile_pic);
  };

//   const unSyncDetail = () => {
//     // remove token
//     localStorage.removeItem("token");
//     localStorage.removeItem("fullname");
//     localStorage.removeItem('type');
//     localStorage.removeItem("name");
//     localStorage.removeItem("firstname");
//     localStorage.removeItem("lastname");
//     localStorage.removeItem("email");
//     localStorage.removeItem("phone_no");
//     localStorage.removeItem("dob");
//     localStorage.removeItem("gender");
//     localStorage.removeItem("userId");
//     localStorage.removeItem("profilePic");

//     setName(null);
//     setType(null);
//     setFirstName(null);
//     setLastName(null);
//     setEmail(null);
//     setPhoneNo(null);
//     setDob(null);
//     setGender(null);
//     setUserId(null);
//     setProfilePic(null);
//   };


const getImageName = (url) => {
    return url.substring(url.lastIndexOf('/') + 1);
}




  return (
    <>
        {
           (loading)
           ? 
            <Loader />
          :
          null
        }
      <Header />
                <div className="innerPageBanner">
                    <img src={InnerBanner} alt="Inner Page Banner" />
                    <h2>Profile</h2>
                </div>
                <section className="profileSec">
                    <Container>
                        <Row>
                            <Col sm={3} className="column-three">
                                <LoginSidebar Userprofile={profilePic} Profilename={name} id={userId}/>
                                <Logout/>
                            </Col>
                            <Col sm={9} className="column-nine">
                            <div className="tabTitle">
                                <h4>Kingdoms</h4>
                            </div>
                            <Tabs
                                defaultActiveKey="home"
                                id="uncontrolled-tab-example"
                                className="innerTabNav"
                                activeKey={activeTab} onSelect={(k) => setActiveTab(k)}
                            >
                                <Tab eventKey="home" title="Inprogress">
                                <div className="row">

                                    {orders.length === 0 ? (
                                        (loading)
                                        ? 
                                            <Loader />
                                        :
                                        <div className="refeeralCode">
                                            <span>
                                                No Active Orders: 
                                            </span>
                                        </div>

                                    ) : (
                                    orders.map((order, index) => (
                                        <div className="col-md-4" key={order.id}>
                                        <div className="kingdomBox">
                                            <div className="kingdomBoxHeader">
                                            <Link
                                                to={`/kingdomdetail/${order.id}`}
                                                > <img
                                                src={order.product_pic}
                                                alt="kingdom box"
                                            /></Link>
                                            <div className="refeeralCode">
                                                <span>
                                                Referral Code: {order.order_code}
                                                </span>
                                                <button
                                                onClick={() =>
                                                    copyReferralCode(order.order_code)
                                                }
                                                >
                                                <img
                                                    src={ClipboardImg}
                                                    alt="Clipboard"
                                                />
                                                </button>
                                            </div>
                                            </div>
                                            <div className="kingdomBoxBody">
                                           <h3> <Link
                                                to={`/kingdomdetail/${order.id}`}>{order.product_name}</Link></h3>
                                            <p>{order.product_detail}.</p>
                                            </div>
                                            <div className="kingdomBoxFooter">
                                            <div className="kingdomPrice-Btn">
                                                <span>
                                                USDT {order.product_price}
                                                </span>

                                                <Link
                                                to={`/kingdomdetail/${order.id}`}
                                                >
                                                View
                                                </Link>
                                            </div>
                                            {/* <ul>
                                                {order.children.length === 0
                                                ? null
                                                : order.children.map(
                                                    (child, index) => (
                                                        <li>
                                                       
                                                        </li>
                                                    )
                                                    )}
                                            </ul> */}
                                            <ul>
                                                {order.children.length === 0
                                                    ? null
                                                    : order.children.map((child, index) => {
                                                       
                                                        let imgSrc;

                                                        
                                                        if (getImageName(child.profile_pic) === "no_image.png") {
                                                            switch (index) {
                                                                case 0:
                                                                    imgSrc = prod1;
                                                                    break;
                                                                case 1:
                                                                    imgSrc = prod2;
                                                                    break;
                                                                case 2:
                                                                    imgSrc = prod3;
                                                                    break;
                                                                default:
                                                                    imgSrc = "http://localhost:8000/uploads/no_image.png"; // Optional: Fallback image
                                                            }
                                                        } else {
                                                            imgSrc = child.profile_pic;
                                                        }

                                                        return (
                                                            <li key={index}>
                                                                <img src={imgSrc} alt="Member One" />
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                            <div className="transactionHistory">
                                                {/* <a
                                                href={
                                                    SOLCAN_URL +
                                                    order.trnx +
                                                    SOLCAN_CLUSTER
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                >
                                                View transaction{" "}
                                                </a> */}
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    ))
                                    )}
                                </div>
                                </Tab>
                                <Tab eventKey="profile" title="Completed">
                                <div className="row">
                                    {completedOrders.length === 0 ? (
                                    <div className="refeeralCode">
                                        <span>
                                            No completed Orders: 
                                        </span>
                                    </div>

                                    ) : (
                                    completedOrders.map((order, index) => (
                                        <div className="col-md-4" key={order.id}>
                                        <div className="kingdomBox" >
                                            {order.status === 2 && (
                                                <div className="kingdomPrice-Btn" style={{position:'relative', top:'36px', zIndex: '99'}}>
                                                    <button >Sold</button>
                                                </div>
                                            )}
                                            <div className="kingdomBoxHeader">
                                            <Link
                                                to={`/kingdomdetail/${order.id}`}> <img
                                                src={order.product_pic}
                                                alt="kingdom box"
                                            /></Link>
                                            <div className="refeeralCode">
                                                <span>
                                                Referral Code: {order.order_code}
                                                </span>
                                                <button
                                                onClick={() =>
                                                    copyReferralCode(order.order_code)
                                                }
                                                >
                                                <img
                                                    src={ClipboardImg}
                                                    alt="Clipboard"
                                                />
                                                </button>
                                            </div>
                                            </div>
                                            <div className="kingdomBoxBody">
                                            <Link
                                                to={`/kingdomdetail/${order.id}`}>
                                                <h3>{order.product_name}</h3>
                                            </Link>
                                            
                                            <p>{order.product_detail}.</p>
                                            <p>STATUS : {((order.status) && (order.status) == 1) ? 'Not Paid' : 'Paid (x2)' }.</p>
                                            </div>
                                            <div className="kingdomBoxFooter">
                                            <div className="kingdomPrice-Btn">
                                                <span>
                                                USDT {order.product_price}
                                                </span>
                                                
                                                {order.status === 2 ? (
                                                    <button >Sold</button>
                                                  
                                                ) : order.req_payment === '' ? (
                                                    <button  onClick={() => handleSellClick(userId, order.id)}>Sell</button>
                                                ) : order.req_payment === 1 ? (
                                                    <button >Proccessing</button>
                                                ) : (
                                                    null // Handle any other cases if needed
                                                )}
                                               
                                                
                                            </div>
                                            
                                            <div className="transactionHistory">
                                                { order.status === 2 ? (
                                                    <p >Congratulations! {order.product_price * 2}USDT has been successfully transferred to your wallet</p>
                                                ) : order.req_payment === 1  ? (
                                                    <p >your payment will be received within 48 hours</p>
                                                ): ( 
                                                    null // Handle any other cases if needed
                                                )}
                                                {order.return_trnx ? (
                                                <a
                                                href={
                                                    SOLCAN_URL +
                                                    order.return_trnx +
                                                    SOLCAN_CLUSTER
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                >
                                                View transaction{" "}
                                                </a>
                                                ): null}
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    ))
                                    )}
                                </div>
                                </Tab>
                            </Tabs>
                            </Col>
                        </Row>
                    </Container>
                </section>
            

      <NewsletterHome />
      <Footer />
    </>
  );
}

export default Kingdoms;
