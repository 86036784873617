// Filename - pages/contact.js
import './portfolio.css';
import ProductCard from "../../components/ProductCard/ProductCard";
// import { fetchMyProducts } from '../../services/ProductService';
import { fetchActiveOrders, fetchCompletedOrders} from '../../services/OrderService';
import { Button } from "react-bootstrap";
import React, { useState } from 'react';
import { useEffect } from "react";
import { useStore } from '../../store/store';
import { toast } from "react-toastify";
import { isLoggedIn } from '../../services/AuthService';

const Portfolio = () => {
	const [isActive, setIsActive] = useState(null);
	const [activeOrdrs, setActiveOrdrs] = useState([]);
	const [completedOrdrs, setCompletedOrdrs] = useState([]);
	
    const walletStatus = useStore((state) => state.walletStatus);
	useEffect(() => {
		const fetchData = async () => {
			const flag = await isLoggedIn();
			if(flag){
				
				const record1 = await fetchActiveOrders();
				if(record1 !=null){
					setActiveOrdrs(record1.data);
				}
			
				const record2 = await fetchCompletedOrders();
				if(record2 !=null){
					setCompletedOrdrs(record2.data);
				}
			}
			// console.log(productsData.data);
		};
		fetchData();
		// console.log(activeOrdrs)
	}, []);
	
	return (
		<>
			<section>
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<h1>
								My Active Orders
							</h1>
						</div>
					</div>
					<div className="row">
						{ 
							activeOrdrs.length === 0 ? 
							(
								<p>No active orders found</p>
							) : 
							(
								activeOrdrs.map((product, index) => (
									<div className='col-sm-3 p-4'  key={product.id}>
										<ProductCard product={product} isActive={walletStatus} isOrder={true}  />
									</div>
								))
							)
						}
					</div>

				
					
				</div>
			</section>

			<section>
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<h1>
								My Completed Orders
							</h1>
						</div>
					</div>
					<div className="row">
						{ 
							completedOrdrs.length === 0 ? 
							(
								<p>No completed orders found</p>
							) : 
							(
								completedOrdrs.map((product, index) => (
									<div className='col-sm-3 p-4'  key={product.id}>
										<ProductCard product={product} isActive={walletStatus} isOrder={true}  />
									</div>
								))
							)
						}
					</div>


					
				</div>
			</section>
			</>

		
	);
};

export default Portfolio;
