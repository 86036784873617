import "./login.css";
import "../profile/profile.css";

import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import Header from "../../components/Header/Header";
import NewsletterHome from "../../components/NewsletterHome/NewsletterHome";
import Footer from "../../components/Footer/Footer";
import { toast } from "react-toastify";
import InnerBanner from "../../images/inner-banner.webp";
import Loader from "../../components/Loader/Loader";
const ForgotPassword = () => {
  const APP_BASE_URL = process.env.REACT_APP_BASE_URL || '';
    const [email, setEmail] = useState('');
    
    const [loading, setLoading] = useState(false);

    const validateEmail = (email) => {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const localPart = email.split('@')[0];
  
      if (!emailRegex.test(email)) {
        return false;
      }
  
      if (email === 'example@example.com' || localPart.includes('example')) {
        return false;
      }
  
      return true;
    };

    const handleForgotPassword = async (e) => {
      e.preventDefault();
      if (!validateEmail(email)) {
        toast.error('Please enter a valid email address.');
        return;
     }else{
      setLoading(true);
      if (!email) {
        setLoading(false); // Hide loader
        toast.error("Email are required."); // Set an error message
        return; // Stop the form submission
      }

      try {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: email
          }),
        };
          const response = await fetch(APP_BASE_URL + "/forgot-password", requestOptions);
          const data = await response.json();
          
          if (data.success == true) {
             toast.success(data.message);
          } else if (data.success == false) {
            toast.error(data.message);
          }
          
      } catch (error) {
        
        toast.error("Error:", error);
      } finally {
          setLoading(false);
      }
    }
  };

	return (
		<>
            <Header/>
            {
           (loading)
           ? 
            <Loader />
          :
          null
        }
            <div className="innerPageBanner">
                <img src={InnerBanner} alt='Inner Page Banner'/>
                <h2>Forget Password</h2>
            </div>
            <section className="formSec">
              <div className="container">
                <div className="formBox">
                  <div className="loginForm">
                    <h3>
                      Lost Your <br /> Password!
                    </h3>
                    <p>
                    Please enter your email address. You will receive an email message with instructions on how to reset your password.
                    </p>
                    <Form onSubmit={handleForgotPassword}>
                      <Form.Control
                        type="email"
                        id="inputEmail"
                        placeholder="Enter Your Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />

                      <Button type="submit" className="loginBtn">
                        Get New Password
                      </Button>
                      <Link to="/account" className="formSwitch" >Login Your Account</Link>
                    </Form>
                  </div>
                </div>
              </div>
            </section>

            <NewsletterHome/>
            <Footer/>
        </>
	);
};

export default ForgotPassword;
