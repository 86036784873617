// Filename - pages/contact.js
import "./OurProducts.css";
import { Link } from "react-router-dom";
import ProductCard from "../../components/ProductCard/ProductCard";
import { fetchProducts } from '../../services/ProductService';
import React, { useState } from 'react';
import { useEffect } from "react";
import { useStore } from '../../store/store';

import scrollDown from "../../images/Scroll-Down.gif";
import BirdImg from "../../images/Bird-Flip.gif";
const OurProducts = () => {
	const [products, setProducts] = useState([]);
	
    const walletStatus = useStore((state) => state.walletStatus);
    const updateEtherPrice = useStore((state) => state.setEtherPrice);
	useEffect(() => {
		
		const fetchData = async () => {
		  const productsData = await fetchProducts();
		  setProducts(productsData.data);
		//   console.log(productsData.data);
		  updateEtherPrice(3563.77);
		};
		fetchData();
		// console.log(products)
	}, []);
	
	return (
		<section className="ourProductSec" id="products">
            <div className='scrollDown'>
			<Link
	                to="/home"
	                onClick={() => {
	                    setTimeout(() => {
	                    const productsSection = document.getElementById("products");
	                    if (productsSection) {
	                        window.scrollTo({
	                        top: productsSection.offsetTop,
	                        behavior: "smooth"
	                        });
	                    }
	                    }, 100); // Adjust the delay as needed to ensure correct scrolling
	                }}
	                >
	                <img src={scrollDown} alt='Scroll Down' />
                </Link>
              
            </div>
			<div className="container">
				<div className="row secTitle">
					<div className="col-md-6">
						<h1 className="ourProductSecTitle">Our Products</h1>
					</div>
                    <div className="col-md-6">
						<p>Discover the keys to your digital empire. Our products offer not just a game, but an experience. Dive into a world where strategic decisions shape realms and fortunes are won with skill and wit. Explore our offerings and embark on your journey to greatness!</p>
					</div>
				</div>
				<div className="row">
					{ 
						products.length === 0 ? 
						(
							<p>No products found</p>
						) : 
						(
							products.map((product, index) => (
								<div className='col-md-6' key={index}>
									<ProductCard product={product} isActive={walletStatus} isOrder={false}  />
								</div>
							))
						)
					}
				</div>
			</div>
		</section>
	);
};

export default OurProducts;
