// import './login.css';
import '../../pages/profile/profile.css';
import React, { useState, useRef, useEffect} from "react";


import Nav from 'react-bootstrap/Nav';
import { NavLink } from "react-router-dom";

import AccountIcon from "../../images/account-icon.webp";
import KingdomIcon from "../../images/kingdom-icon.webp";
import ChangePassIcon from "../../images/change-pass-icon.webp";
// import ConnectWallet from "../../images/wallet-icon.png";

import AccountIconActive from "../../images/account-icon-active.webp";
import KingdomIconActive from "../../images/kingdom-icon-active.webp";
import ChangePassIconActive from "../../images/change-pass-icon-active.webp";
import ImageEdite from "../../images/images-edite.webp";
import Loader from "../../components/Loader/Loader";
import { toast } from "react-toastify";
import WalletIcon from "../../images/wallet-icon.webp";
import WalletIconActive from "../../images/wallet-icon-active.png";
const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const LoginSidebar = ({Userprofile, Profilename, id}) => {
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(Userprofile);
    const fileInputRef = useRef(null);
  
    useEffect(() => {
        const storedProfileImageUrl = localStorage.getItem('profilePic');
        if (storedProfileImageUrl) {
            setSelectedFile(storedProfileImageUrl);
        } else {
            setSelectedFile(Userprofile);
        }
    }, [Userprofile]);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            
        // Check allowed file types (PNG, JPG, SVG)
        const allowedTypes = ['image/png', 'image/jpeg', 'image/webp'];
        if (!allowedTypes.includes(file.type)) {
            toast.error('Selected file type is not supported. Please upload a PNG, JPG, or webp file.');
            return;
        }

        // Check file size (in bytes)
        const fileSizeLimit = 5 * 1024 * 1024; // 10 MB
        if (file.size > fileSizeLimit) {
            toast.error('File size exceeds limit (5MB). Please choose a smaller file.');
            return;
        }
            
            await handleUpload(file);
        } else {
            console.log('No file selected.');
        }
    };

    const handleUpload = async (file) => {
        
        if (!file) {
            console.error(file);
            return;
        }

        const formData = new FormData();
        formData.append('profile_pic', file);
       
        setLoading(true);
        try {
            const response = await fetch(`${APP_BASE_URL}/upload-profile-image/${id}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: formData,
            });
            
            const data = await response.json();
           
           if(data.success){
                localStorage.setItem('profilePic', data.message);
                setSelectedFile(data.message)
                toast.success('Profile Successfully Updated.');
           }
        } catch (error) {
            toast.error('Error uploading file:', error);
            
        }finally {
            setLoading(false); 
          }
    };
    const handleIconClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger file input click
        }
    };

    return (
        <>
         {
           (loading)
           ? 
            <Loader />
          :
          null
        }
          <div className="loginUserName">
                <div className="userProfileImg">
                    <img src={selectedFile} alt="User Profile" />
                    <img
                    className="iconprofile"
                    src={ImageEdite}
                    alt="Upload Icon"
                    onClick={handleIconClick}
                    style={{ cursor: 'pointer' }}
                    />
                </div>
                <div className="userName">
                    <p>Hello</p>
                    <h3>{Profilename}</h3>
                </div>
                {/* Hidden file input */}
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
                {/* Image icon to trigger file input */}
                
      
                
            </div>
            <Nav variant="pills" className="flex-column">

                <NavLink exact activeClassName="active" to="/my_account" className='nav-link'>
                    <img src={AccountIcon} alt="Account Icon" className="tabIcon" />
                    <img src={AccountIconActive} alt="Account Icon Active" className="tabActiveIcon" /> 
                    <span>Dashboard</span>
                </NavLink>
                
                <NavLink activeClassName="active" to="/kingdoms" className='nav-link'>
                    <img src={KingdomIcon} alt="Kingdoms Icon" className="tabIcon" />
                    <img src={KingdomIconActive} alt="Kingdoms Icon Active" className="tabActiveIcon" />
                    <span>Realms</span>
                </NavLink>
                <NavLink activeClassName="active" to="/wallet" className='nav-link'>
                    <img src={WalletIcon} alt="Wallet Icon" className="tabIcon" />
                    <img src={WalletIconActive} alt="Wallet Icon Active" className="tabActiveIcon" />
                    <span>Wallet</span>
                </NavLink>

                <NavLink activeClassName="active" to="/change_password" className='nav-link'>
                    <img src={ChangePassIcon} alt="Change Password Icon" className="tabIcon" />
                    <img src={ChangePassIconActive} alt="Change Password Icon Active" className="tabActiveIcon" />
                    <span>Change Password</span>
                </NavLink>

               {/* <Nav.Item>
                    <Nav.Link eventKey="third">
                        <img src={ChangePassIcon} alt="Change Password Icon" className="tabIcon" />
                        <img src={ChangePassIconActive} alt="Change Password Icon Active" className="tabActiveIcon" />
                        <span>Change Password</span>
                    </Nav.Link>
                </Nav.Item>
                 <Nav.Item>
                    <Nav.Link eventKey="fourth">
                        <img src={ConnectWallet} alt="Connect Wallet Icon" className="tabIcon" />
                        <img src={ConnectWalletActive} alt="Connect Wallet Icon Active" className="tabActiveIcon" />
                        <span>Connect Wallet</span>
                    </Nav.Link>
                </Nav.Item> */}
            </Nav>
        </>
    );

}

export default LoginSidebar;