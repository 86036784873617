
import { toast } from "react-toastify";
const APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const signUp  = async (params, setLoading) => {
    setLoading(true);
  try {
        const requestOptions = {
            method: 'POST',
            headers: {
                        'Content-Type': 'application/json',
                         'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    },
          
            body: JSON.stringify({
                    firstname: params['firstName'],
                    lastname: params['lastName'],
                    email: params['email'],
                    phone_no: params['phoneNo'],
                    password: params['password'],
                    password_confirmation: params['passwordConfirmation'],
            }),
        };
        const response    = await fetch(APP_BASE_URL + "/register", requestOptions);
        const data        = await response.json();
        // console.log('Response:', data);
        if (data.success) {
            toast.success(data.message);
            return true;
        }else{
            if(data.data.error){
            toast.error(data.data.error);
            }else{
                let allErrors = '';
                for (const property in data.data) {
                    allErrors = ' ' + data.data[property] + '.';
                }
                toast.error(allErrors);
            }
            return false;
        }
  } catch (error) {
    console.log('Error:', error);
    return false;
  }finally {
    setLoading(false); 
  }
}

const isLoggedIn = async() => {
    if((localStorage.getItem('token'))){
        return true;
    }else{
        toast.error('Please login first');
        return false;
    }   
}



export { isLoggedIn, signUp};