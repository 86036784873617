// Loader.js
import React from 'react';
import './Loader.css'; // CSS file import karen

const Loader = () => {
  return (
    <div className="loading-overlay" >
        <div className="spinners"></div>
        <div className="loader-text">
        <h5>Please Wait.......</h5>
      </div>
    </div>

  );
};

export default Loader;
