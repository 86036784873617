import React from 'react';
import "./HowItWorks.css";

import HowItBg from "../../images/how.gif";

const HowItWorks = () => {
    return (
       <section className='HowItWorks' id='HowItWorks'>
            <div className="container">
                <div className="HowItContent">
                    <div className="HowItHead">

                    </div>
                    <div className="HowItmiddle">
                        <div className="row">
                            <div className="col-md-6 how-img">
                                <img src={HowItBg}/>
                            </div>
                            <div className="col-md-6">
                                <div className="how-des">
                                    <h1>Who We Are?</h1>
                                    <p>Realm of Royals is a visionary company that has been a leader in the blockchain industry for years. We have successfully created and launched numerous projects, showcasing our strong foundation and the exceptional expertise of our team. Our highly skilled tech experts have worked diligently to build a company that stands out in the blockchain arena. After extensive brainstorming and hard work, our company decided to develop a unique game and virtual realm platform where users can benefit and earn money. This project leverages our blockchain expertise to enter the trending market and create a game that rewards every player. We have strong collaborations with our investors, who share our vision and have shown great interest in our project. Currently, we are in the process of partnering with high-tech companies to join us in building an unprecedented community. We are excited to present our vision to our audience.</p>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default HowItWorks;