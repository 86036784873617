import React from 'react';
import "./MoneyBack.css";

import moneyBackImg from "../../images/money-back-img.webp";

const MoneyBack = () => {
    return (
       <section className='moneyBackSec'>
            <div className='container'>
                <div className='moneyBackMain'>
                    <div className='moneyBackImg'>
                        <img src={moneyBackImg} alt='Money Back'/>
                    </div>
                    <div className='moneyBackText'>
                        <h1>get 100% Money back guarantee</h1>
                        <ul className='moneyBackList'>
                            <li>Users must not be affiliated with any referral code and must be the direct purchaser of the package.</li>
                            <li>If no users join under the user's name after package purchase.</li>
                            <li>Refund applicable within 60 days of package purchase; no refunds beyond this period.</li>
                        </ul>
                        <a href='/my_account' className='registerBtn'>Register Now</a>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default MoneyBack;