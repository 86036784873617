import { toast } from "react-toastify";
const APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const storeOrder  = async (productId, referralCode, trxId, walletAddress) => {
 
// console.log(localStorage.getItem('token'))
  try {
      const requestOptions = {
          method: 'POST',
          headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + localStorage.getItem('token'),
                  },
          body: JSON.stringify({
            product_id: productId,
            code: referralCode,
            trnx: trxId,
            wallet_address: walletAddress,
          })
      };

      const response  = await fetch(APP_BASE_URL+'/storeOrder', requestOptions);
      const data      = await response.json();
      // console.log('Response:', data);
      if (data.success) {
          toast.success(data.message);
      }else{
        console.log(data)
        if(data.data.error){
          toast.error(data.data.error);
        }else{
          let allErrors = '';
          for (const property in data.data) {
              allErrors = ' ' + data.data[property] + '.';
            }
            
          toast.error(allErrors);
          // alert(allErrors);
        }
      }
    // Redirect or update state to indicate successful login
  } catch (error) {
    console.log('Error:', error);
    // Handle error, e.g., display an error message to the user
  }

}

const validateOrder  = async (productId, referralCode) => {
 // setLoading(true);
  // console.log(localStorage.getItem('token'))
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    },
            body: JSON.stringify({
              product_id: productId,
              code: referralCode
            })
        };
  
        const response  = await fetch(APP_BASE_URL+'/validateOrder', requestOptions);
        const data      = await response.json();
        // console.log('Response:', data);
        if (data.success) {
            return true;
        }else{
          // console.log(data)
          if(data.data.error){
            toast.error(data.data.error);
          }else{
            let allErrors = '';
            for (const property in data.data) {
                allErrors = ' ' + data.data[property] + '.';
              }
            toast.error(allErrors);
            // alert(allErrors);
          }
          return false;
        }
      // Redirect or update state to indicate successful login
    } catch (error) {
      // Handle error, e.g., display an error message to the user
      console.log('Error:', error);
      return false;

    }finally {
     // setLoading(false); 
    }
  
  }

  

  const returnsPaid  = async (orderId, returnTrnx) => {
    // console.log(localStorage.getItem('token'))
      try {
          const requestOptions = {
              method: 'POST',
              headers: {
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer ' + localStorage.getItem('token'),
                      },
              body: JSON.stringify({
                order_id: orderId,
                return_trnx: returnTrnx,
              })
          };
    
          const response  = await fetch(APP_BASE_URL+'/returnsPaid', requestOptions);
          const data      = await response.json();
          // console.log('Response:', data);
          if (data.success) {
              return true;
          }else{
            // console.log(data)
            if(data.data.error){
              toast.error(data.data.error);
            }else{
              let allErrors = '';
              for (const property in data.data) {
                  allErrors = ' ' + data.data[property] + '.';
                }
              toast.error(allErrors);
              // alert(allErrors);
            }
            return false;
          }
        // Redirect or update state to indicate successful login
      } catch (error) {
        // Handle error, e.g., display an error message to the user
        console.log('Error:', error);
        return false;
  
      }
    
    }

const validateReturnsPaid  = async (orderId) => {
  // console.log(localStorage.getItem('token'))
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    },
            body: JSON.stringify({
              order_id: orderId,
            })
        };
  
        const response  = await fetch(APP_BASE_URL+'/validateReturnsPaid', requestOptions);
        const data      = await response.json();
        // console.log('Response:', data);
        if (data.success) {
            return true;
        }else{
          // console.log(data)
          if(data.data.error){
            toast.error(data.data.error);
          }else{
            let allErrors = '';
            for (const property in data.data) {
                allErrors = ' ' + data.data[property] + '.';
              }
            toast.error(allErrors);
            // alert(allErrors);
          }
          return false;
        }
      // Redirect or update state to indicate successful login
    } catch (error) {
      // Handle error, e.g., display an error message to the user
      console.log('Error:', error);
      return false;

    }
  
  }
  

const fetchOrderDetail = async(orderId) => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      }
    };

    const response  = await fetch(APP_BASE_URL+`/orderDetail/${orderId}`, requestOptions);

    if (!response.ok) {
      throw new Error('Failed to fetch order detail');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    toast.error('Error fetching orders:', error);
    return null;
  }
}

const fetchOrderChildren = async(orderId) => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      }
    };

    const response  = await fetch(APP_BASE_URL+`/orderChildren/${orderId}`, requestOptions);

    if (!response.ok) {
      throw new Error('Failed to fetch order children');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    toast.error('Error fetching orders:', error);
    return null;
  }
}


const fetchOrderParent = async(orderId) => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      }
    };

    const response  = await fetch(APP_BASE_URL+`/orderParent/${orderId}`, requestOptions);

    if (!response.ok) {
      throw new Error('Failed to fetch order parent');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    toast.error('Error fetching orders:', error);
    return null;
  }
}



const fetchActiveOrders = async() => {
  try {

    const requestOptions = {
      method: 'GET',
      headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + localStorage.getItem('token'),
              },
      
    };

  const response  = await fetch(APP_BASE_URL+'/activeOrders', requestOptions);


    if (!response.ok) {
      throw new Error('Failed to fetch orders');
    }
    const data = await response.json();
    return (data);
  } catch (error) {
      toast.error('Error fetching orders:', error);
      // console.error('Error fetching orders:', error);
    return null;
  }
}


const fetchAllTransactions = async() => {
  try {

    const requestOptions = {
      method: 'GET',
      headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + localStorage.getItem('token'),
              },
      
    };

  const response  = await fetch(APP_BASE_URL+'/fetchAllTransactions', requestOptions);


    if (!response.ok) {
      throw new Error('Failed to fetch orders');
    }
    const data = await response.json();
    return (data);
  } catch (error) {
      toast.error('Error fetching orders:', error);
      // console.error('Error fetching orders:', error);
    return null;
  }
}

const fetchAllTransactionsOrder = async() => {
  try {

    const requestOptions = {
      method: 'GET',
      headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + localStorage.getItem('token'),
              },
      
    };

  const response  = await fetch(APP_BASE_URL+'/fetchAllTransactionsOrder', requestOptions);


    if (!response.ok) {
      throw new Error('Failed to fetch orders');
    }
    const data = await response.json();
    return (data);
  } catch (error) {
      toast.error('Error fetching orders:', error);
      // console.error('Error fetching orders:', error);
    return null;
  }
}


const fetchOrder = async(orderId) => {
  try {

    const requestOptions = {
      method: 'GET',
      headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + localStorage.getItem('token'),
              },
      
    };
    const response  = await fetch(APP_BASE_URL+`/fetchOrder/${orderId}`, requestOptions);

    if (!response.ok) {
      throw new Error('Failed to fetch orders');
    }
    const data = await response.json();
    return (data);
  } catch (error) {
      toast.error('Error fetching orders:', error);
      // console.error('Error fetching orders:', error);
    return null;
  }
}

const fetchCompletedOrders = async() => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + localStorage.getItem('token'),
              },
      
    };

    const response  = await fetch(APP_BASE_URL+'/completedOrders', requestOptions);

    if (!response.ok) {
      throw new Error('Failed to fetch orders');
    }
    const data = await response.json();
    return (data);
  } catch (error) {
    toast.error('Error fetching orders:', error);
    // console.error('Error fetching orders:', error);
    return null;
  }
}



const fetchAllCompletedOrders = async() => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + localStorage.getItem('token'),
              },
      
    };

    const response  = await fetch(APP_BASE_URL+'/allCompletedOrders', requestOptions);

    if (!response.ok) {
      throw new Error('Failed to fetch orders');
    }
    const data = await response.json();
    return (data);
  } catch (error) {
    toast.error('Error fetching orders:', error);
    // console.error('Error fetching orders:', error);
    return null;
  }
}




export { 
          storeOrder, 
          validateOrder, 
          validateReturnsPaid,
          returnsPaid,
          fetchActiveOrders, 
          fetchAllTransactions, 
          fetchAllTransactionsOrder, 
          fetchOrder, 
          fetchCompletedOrders, 
          fetchAllCompletedOrders,
          fetchOrderDetail, 
          fetchOrderChildren,
          fetchOrderParent
        };