import React, {useState} from 'react';
import "./GameKingdom.css";
import "../../../node_modules/video-react/dist/video-react.css";
import { Player, ControlBar  } from 'video-react';

import comingSoon from "../../images/Coming-Soon.png";
//import comingSoonVideo from "../../videos/coming_soon_video.mp4";

const GameKingdom = () => {
    const [isIframeLoaded, setIsIframeLoaded] = useState(false);
    const handlePosterClick = () => {
        setIsIframeLoaded(true);
    };
    return (
       <section className='gameKingdomSec'>
            <div className='container'>
                <div className='secTitle'>
                    <h1>Our Game</h1>
                </div>
                <div className='secTitle kingdomText'>
                    <h1>Your Realm</h1>
                    <p>Begin on a thrilling journey where you rule your realms, make strategic decisions, and build your empire. Our game, Your realm, awaits your command. Are you ready to conquer the realm and double your investment?</p>
                </div>
            </div>
            <div className='kingdomComingSoon'>
                {!isIframeLoaded && (
                    <img
                        src={comingSoon}
                        alt="Poster"
                        //style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                        onClick={handlePosterClick}
                    />
                )}
                {isIframeLoaded && (
                    <iframe
                    width="100%"
                    height="690"
                    src="https://www.youtube.com/embed/8IdVxz3S0GU?rel=0&autoplay=1"
                    frameborder="0"
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
                )}
            </div>
       </section>
    );
};

export default GameKingdom;