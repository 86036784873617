import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";
import InnerBanner from "../../images/inner-banner.webp";
const VerifyEmail = () => {
    const APP_BASE_URL = process.env.REACT_APP_BASE_URL || '';
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const { pathname } = location;

    const [, , id, hash] = pathname.split('/');
    useEffect(() => {
        const verifyEmail = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${APP_BASE_URL}/verify-email/${id}/${hash}`, {
                    method: 'POST',
                    headers: { "Content-Type": "application/json" },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                const data = await response.json();
                setMessage(data.message);
            } catch (error) {
                console.error('Error verifying email:', error);
                setMessage('Error verifying email.');
            } finally {
                setLoading(false);
            }
        };

        if (id && hash) {
            verifyEmail();
        } else {
            setMessage('Invalid verification link.');
            setLoading(false);
        }
    }, [id, hash]);

   

    return (

         <>
          <Header />
          {
           (loading)
           ? 
            <Loader />
          :
          null
        }
           <div className="innerPageBanner">
              <img src={InnerBanner} alt="Inner Page Banner" />
              <h2>{message}</h2>
            </div>
            <Footer/>
        </>
    );
};

export default VerifyEmail;
