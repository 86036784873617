import React from 'react';
import "./SevenLevels.css";

const SevenLevels = () => {
    return (
       <section className='SevenLevels' id='SevenLevels'>
            <div className="container">
                <div className='secTitle text-center'>
                    <h1>Unlock Unlimited Earnings with Our Exclusive 7-Level Referral Program!</h1>
                    <div className='SevenLevelsSubText'>
                        <h2>Golden Opportunity: </h2>
                        <p>Seize the chance to maximize your earnings by referring others, but hurry—this opportunity is available only to the first 100 users! Our comprehensive 7-level referral program is designed to reward you generously:</p>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-4'>
                        <div className='LevelBox'>
                            <h3>Level 1 Referral Users:</h3>
                            <p>Earn 25% of the total package amount for each referral at Level 1. With no limits on referrals, your earnings can skyrocket. For example, if you purchase a $1,000 package, you’ll receive $250 for every user you refer at Level 1.</p>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='LevelBox'>
                            <h3>Level 2 Referral Users:</h3>
                            <p>Earn 15% of the package amount for each user referred by your Level 1 connections. That’s $150 for every $1,000 package sold through your referrals.</p>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='LevelBox'>
                            <h3>Level 3 Referral Users:</h3>
                            <p>Continue to earn as your network expands, with 10% of the package amount for each Level 3 referral. For instance, that’s $100 per $1,000 package purchased by a Level 3 user.</p>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='LevelBox'>
                            <h3>Level 4 Referral Users:</h3>
                            <p>As your network grows further, earn 7% of the package amount for each Level 4 referral. That’s $70 per $1,000 package.</p>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='LevelBox'>
                            <h3>Level 5 Referral Users:</h3>
                            <p>Receive 5% of the package amount for each user referred at Level 5, adding $50 for every $1,000 package.</p>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='LevelBox'>
                            <h3>Level 6 Referral Users:</h3>
                            <p>Earn 2% of the package amount for each Level 6 referral, which equates to $20 per $1,000 package sold.</p>
                        </div>
                    </div>
                    <div className='col-md-4'></div>
                    <div className='col-md-4'>
                        <div className='LevelBox'>
                            <h3>Level 7 Referral Users:</h3>
                            <p>Finally, earn 1% of the package amount for each Level 7 referral, which is $10 for every $1,000 package purchased.</p>
                        </div>
                    </div>
                    <div className='col-md-4'></div>
                </div>

                <div className='PackegAmount'>
                    <h2>Package Amount $1000</h2>
                </div>
                <div className="SevenLevelsTable table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>User Referral Levels</th>
                                <th>Percent %</th>
                                <th>Amount Per user</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Level 1</td>
                                <td>25 %</td>
                                <td>$250</td>
                            </tr>
                            <tr>
                                <td>Level 2</td>
                                <td>15 %</td>
                                <td>$150</td>
                            </tr>
                            <tr>
                                <td>Level 3</td>
                                <td>10 %</td>
                                <td>$100</td>
                            </tr>
                            <tr>
                                <td>Level 4</td>
                                <td>7 %</td>
                                <td>$70</td>
                            </tr>
                            <tr>
                                <td>Level 5</td>
                                <td>5 %</td>
                                <td>$50</td>
                            </tr>
                            <tr>
                                <td>Level 6</td>
                                <td>2 %</td>
                                <td>$20</td>
                            </tr>
                            <tr>
                                <td>Level 7</td>
                                <td>1 %</td>
                                <td>$10</td>
                            </tr>
                        </tbody>
                       
                    </table>
                </div>
            </div>
       </section>
    );
};

export default SevenLevels;