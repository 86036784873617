import React from "react";
import './TermsConditions.css';
import Header from "../../components/Header/Header";
import NewsletterHome from "../../components/NewsletterHome/NewsletterHome";
import Footer from "../../components/Footer/Footer";

import InnerBanner from "../../images/inner-banner.webp";

const TermsConditions = () => {
	return (
		<>
            <Header/>
            <div className="innerPageBanner" id="TermsCondition">
                <img src={InnerBanner} alt='Inner Page Banner'/>
                <h2>Terms & Conditions</h2>
            </div>

            <section className='TermsConditionsSec'>
                <div className="container">
                    <div className="innerContainer">
                        <p>Welcome to Realm of Royals. These Terms and Conditions govern your access to and use of our website www.realmofroyals.com and services. By accessing or using our website and services, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use our services.</p>
                        
                        <h3>1. Acceptance of Terms</h3>
                        <p>By registering, logging in, and using our services, you agree to comply with and be bound by these Terms. These Terms apply to all users of the website, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content.</p>
                        <h3>2. Eligibility</h3>
                        <p>To use our services, you must be at least 18 years old and capable of entering into a legally binding agreement. By using our services, you represent and warrant that you meet these eligibility requirements.</p>
                        <h3>3. Account Registration</h3>
                        <p>To access certain features of our services, you may need to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for maintaining the confidentiality of your account password and for all activities that occur under your account.</p>
                        <h3>4. Use of Services</h3>
                        <p>You agree to use our services only for lawful purposes and in accordance with these Terms. You agree not to:</p>
                        <ul>
                            <li>Use our services in any way that violates any applicable local, national, or international law or regulation.</li>
                            <li>Engage in any conduct that restricts or inhibits anyone's use or enjoyment of our services, or which may harm us or other users of our services.</li>
                            <li>Impersonate or attempt to impersonate Realm of Royals, a Realm of Royals employee, another user, or any other person or entity.</li>
                            <li>Use any device, software, or routine that interferes with the proper working of our services.</li>
                        </ul>
                        <h3>5. Transactions</h3>
                        <p>When you buy or sell product packages on our platform, you agree to the following:</p>
                        <ul>
                            <li>All transactions are final and non-refundable.</li>
                            <li>You are responsible for the accuracy of the transaction details you provide.</li>
                            <li>Impersonate or attempt to impersonate Realm of Royals, a Realm of Royals employee, another user, or any other person or entity.</li>
                            <li>We are not responsible for any loss or damage arising from your failure to comply with these terms.</li>
                        </ul>
                        <h3>6. Wallet and Payments</h3>
                        <p>You may connect your cryptocurrency wallet to your account to facilitate transactions. You agree that:</p>
                        <ul>
                            <li>You are solely responsible for the management and security of your wallet.</li>
                            <li>We are not responsible for any loss or theft of cryptocurrency from your wallet.</li>
                            <li>All payments and transactions are processed securely, and we do not store any sensitive payment information.</li>
                        </ul>
                        <h3>7. Profit and Earnings</h3>
                        <p>By participating in our platform, you may earn profits from selling product packages. You acknowledge that:</p>
                        <ul>
                            <li>Profit payouts are processed within 48 hours of the transaction.</li>
                            <li>Earnings are subject to applicable taxes and regulations.</li>
                            <li>We do not guarantee any specific level of profit or income.</li>
                        </ul>
                        <h3>8. Intellectual Property</h3>
                        <p>All content on our website, including text, graphics, logos, and software, is the property of Realm of Royals or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, or distribute any content without our prior written permission.</p>
                        <h3>9. Termination</h3>
                        <p>We may terminate or suspend your account and access to our services at our sole discretion, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use our services will immediately cease.</p>
                        <h3>10. Limitation of Liability</h3>
                        <p>To the fullest extent permitted by law, in no event shall Realm of Royals, its affiliates, directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages, including loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of our services.</p>
                        <h3>11. Indemnification</h3>
                        <p>You agree to defend, indemnify, and hold harmless Realm of Royals and its affiliates, directors, employees, and agents from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your use of our services.</p>
                        <h3>12. Changes to Terms</h3>
                        <p>We reserve the right to modify or replace these Terms at any time. If we make material changes, we will notify you by email or by posting a notice on our website prior to the effective date of the changes. Your continued use of our services after the effective date of any changes constitutes your acceptance of the new Terms.</p>
                        <h3>13. Contact Us</h3>
                        <p>If you have any questions about these Terms, please contact us at:</p>
                        <p>Email: contact@realmofroyals.com</p>
                        <p>By using our website and services, you acknowledge that you have read, understood, and agree to be bound by these Terms.</p>
                    </div>
                </div>
            </section>

            <NewsletterHome/>
            <Footer/>
        </>
	);
};

export default TermsConditions;
