import React, { useState} from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store/store";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
const Logout = () => {
    const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const updateWalletStatus = useStore((state) => state.setWalletStatus);
    const updateDefaultAccount = useStore((state) => state.setDefaultAccount);
    const updateNavFlag = useStore((state) => state.setNavFlag);
    // /const navFlag = useStore((state) => state.navFlag);



    const handleLogout = async () => {
        // console.log(localStorage.getItem('token'))
        setLoading(true);
        try {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          };
    
          const response =  await fetch(APP_BASE_URL + "/logout", requestOptions);
          const data = await response.json();

          if (data.success) {
            unSyncDetail();
      
            // Disconnect Wallet
            updateWalletStatus(null);
            updateDefaultAccount(null);
            updateNavFlag(null);
            navigate("/account");
            toast.success("Logged out successfully");
          }else{
            toast.error(data.data.error);
          }
        } catch (error) {
          toast.error("Error:", error);
          unSyncDetail();
          // Handle error, e.g., display an error message to the user
        }finally {
          setLoading(false); 
        }
      };


      const unSyncDetail = () => {
        // remove token
        localStorage.removeItem("token");
        localStorage.removeItem("fullname");
        localStorage.removeItem('type');
        localStorage.removeItem("name");
        localStorage.removeItem("firstname");
        localStorage.removeItem("lastname");
        localStorage.removeItem("email");
        localStorage.removeItem("phone_no");
        localStorage.removeItem("dob");
        localStorage.removeItem("gender");
        localStorage.removeItem("userId");
        localStorage.removeItem("profilePic");
        localStorage.removeItem('walletStatus');
        localStorage.removeItem('accountAddress');
        localStorage.removeItem('walletamount');
        localStorage.removeItem('wallettronamount');
        
      };


    return (
      <>
      {
           (loading)
           ? 
            <Loader />
          :
          null
      }
     
        <div className="logOutBtn">
        <button className="" onClick={handleLogout}>
          Log Out
        </button>
      </div>
      </>
    );
}

export default Logout;
