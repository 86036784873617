import "../login/login.css";
import "../profile/profile.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header/Header";
import LoginSidebar from "../../components/LoginSidebar/LoginSidebar";
import Logout from "../../components/Logout/Logout";
import NewsletterHome from "../../components/NewsletterHome/NewsletterHome";
import WalletInner from "../../components/Wallet/WalletInner.js";
import WithdrawInner from "../../components/Wallet/WithdrawInner.js";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import InnerBanner from "../../images/inner-banner.webp";

function Wallet() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(null);
    const [profilePic, setProfilePic] = useState(null);
    const [userId, setUserId] = useState(null);
    const [activeTab, setActiveTab] = useState('wallet');


    useEffect(() => {
        if(!localStorage.getItem("token")){
          navigate("/account");
        }
        
        setName(localStorage.getItem("name"));
        setUserId(localStorage.getItem("userId"));
        setProfilePic(localStorage.getItem("profilePic"));
       
       
      }, []);


    return (
        <>
            <Header />
            <>
                {
                (loading)
                ? 
                    <Loader />
                :
                null
                }
                <div className="innerPageBanner">
                    <img src={InnerBanner} alt="Inner Page Banner" />
                    <h2>Profile</h2>
                </div>
                <section className="profileSec">
                    <Container>
                        <Row>
                            <Col sm={3} className="column-three">
                                <LoginSidebar Userprofile={profilePic} Profilename={name} id={userId}/>
                                <Logout/>
                            </Col>
                            <Col sm={9} className="column-nine">
                                <div className="tabTitle">
                                <h4>Wallet</h4>
                                </div>
                                <Tabs defaultActiveKey="wallet" id="uncontrolled-tab-example" className="innerTabNav" activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                                    <Tab eventKey="wallet" title="Wallet">
                                        <WalletInner />
                                    </Tab>
                                    <Tab eventKey="withdraw" title="Withdraw">
                                        <WithdrawInner />                  
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
            <NewsletterHome />
            <Footer />
        </>
    );
}
export default Wallet;