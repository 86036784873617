import React, { useState, useEffect } from 'react';
import './profile.css';
import './kindomdetail.css';
import { useNavigate } from "react-router-dom";

import { useStore } from "../../store/store";
import { toast } from "react-toastify";

import Header from "../../components/Header/Header";
import LoginSidebar from "../../components/LoginSidebar/LoginSidebar";
import NewsletterHome from "../../components/NewsletterHome/NewsletterHome";
import Footer from "../../components/Footer/Footer";
import UserTree from "./UserTree";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from "react-bootstrap/Container";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import MemberTree from "./MemberTree"; // Import the MemberTree component
import PrettyTree from "./PrettyTree"; // Import the PrettyTree component
import './UserTree';
import InnerBanner from "../../images/inner-banner.webp";
import ClipboardImg from "../../images/clipboard-img.webp";
import TreeFrame from "../../images/borders-img.webp";
import kingImage from "../../images/king.png";
import advisorImage from "../../images/advisor.png";
import generalImage from "../../images/general.png";
import governerImage from "../../images/governer.png";

import { fetchOrder } from '../../services/OrderService';

import { useParams } from 'react-router-dom';
import { fetchActiveOrders, fetchAllTransactions } from '../../services/OrderService';
import Logout from "../../components/Logout/Logout";

const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const SOLCAN_URL = process.env.REACT_APP_SOLCAN_URL;
const SOLCAN_CLUSTER = process.env.REACT_APP_SOLCAN_CLUSTER;
const APP_TRON_URL = process.env.REACT_APP_TRON_URL;

function Profile() {
    const navigate = useNavigate();
	let { orderId } = useParams();

    const [orders, setOrders] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [name, setName] = useState(null);
    const [profilePic, setProfilePic] = useState(null);
    const [userId, setUserId] = useState(null);
    const updateNavFlag = useStore((state) => state.setNavFlag);
    const navFlag = useStore((state) => state.navFlag);


    useEffect(() => {
        setName(localStorage.getItem("name"));
        setProfilePic(localStorage.getItem("profilePic"));
        const fetchData = async () => {
            const orderData = await fetchOrder(orderId);
            //console.log(orderData);
            setOrders(orderData.data);

            
            const trnxData = await fetchAllTransactions();
            setTransactions(trnxData.data);
          };
          fetchData();
          if(!navFlag){
            //navigate("/account");
          }
      }, []);
      useEffect(() => {
        const navItem = document.querySelector('.nav-pills a:nth-child(2)');
        if (navItem) {
            navItem.style.backgroundColor = '#FFD452';
            navItem.style.color = '#1B1B1B';

            const tabActiveIcon = navItem.querySelector('.tabActiveIcon');
            const tabIcon = navItem.querySelector('.tabIcon');

            if (tabActiveIcon) {
                tabActiveIcon.style.display = 'inline-block';
            }

            if (tabIcon) {
                tabIcon.style.display = 'none';
            }
        }
    }, []);
    const getClassName = (index) => {
        switch (index) {
            case 0:
                return 'treeTwo';
            case 1:
                return 'treeThree';
            case 2:
                return 'treeFour';
            default:
                return '';
        }
    };
    const [copied, setCopied] = useState(false); 
    const copyReferralCode = (code) => {
        navigator.clipboard.writeText(code);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
        alert("Referral code copied to clipboard!");
      };
     

  return (
    
    <>
        <Header/>

        <div className="innerPageBanner">
            <img src={InnerBanner} alt='Inner Page Banner'/>
            <h2>Profile</h2>
        </div>
        <section className="profileSec">
        
            <div className="container">
                
                <Container id="left-tabs-example" defaultActiveKey="second">
                    <Row>
                        <Col sm={3} className="column-three">
                        <LoginSidebar Userprofile={profilePic} Profilename={name} id={userId}/>
                        <Logout/>
                        </Col>
                        <Col sm={9} className="column-nine">
                        
                        <UserTree  />
                        
                        </Col>
                        <Col sm={9} className="column-nine">
                            {/* <div className="tabTitle">
                                <h4>Kingdoms</h4>
                            </div> */}
                            
                            {/* <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="innerTabNav" > */}
                                {/* <Tab eventKey="home" title="Inprogress">
                                    { 
                                        orders.length === 0 ? 
                                        (
                                            <p>No orders found</p>
                                        ) : 
                                        (
                                            orders.map((order, index) => (

                                                <div  key={order.id}>

                                                            
                                                <div className="kingdomDetailHeader">
                                                    <div className="kingdomHeaderColOne">
                                                        <h3>{order.product_name}</h3>
                                                    </div>
                                                    <div className="kingdomHeaderColTwo">
                                                        <p>{order.product_detail}.</p>
                                                    </div>
                                                    <div className="kingdomHeaderColThree">
                                                        <h3>USDT  {order.product_price}</h3>
                                                       
                                                        
                                                        <a href={`${APP_TRON_URL}transaction/${order.trnx}`} target="_blank" rel="noopener noreferrer">
                                                                                View transaction </a>
                                                    </div>
                                                </div>
                                                <div className="treeMain">
                                                {order.status === 2 && (
                                                    <p style={{padding:'10px 60px', color: 'red', border: '1px solid',textAlign: 'center',width:'60%',margin:'0px auto'}}>Your Realm Has Been Sold. <a style={{color: 'red'}} 
                                                    href={`${APP_TRON_URL}transaction/${order.return_trnx}`} target="_blank" rel="noopener noreferrer">
                                                                                View transaction </a></p>
                                                )}
                                                    <div className="treeOne">
                                                        <h4>KING</h4>
                                                        <div className="kingdomBoxHeader">
                                                            <img src={kingImage} alt="Tree King" />
                                                            <div className="refeeralCode">
                                                                <span>Referral Code: {order.order_code}</span>
                                                                <button
                                                                onClick={() =>
                                                                    copyReferralCode(order.order_code)
                                                                }
                                                                >
                                                                <img
                                                                    src={ClipboardImg}
                                                                    alt="Clipboard"
                                                                />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <p>{order.user_name}</p>
                                                    </div>
                                                    <div className="treeFrame">
                                                        <img src={TreeFrame} alt="Tree Frame" />
                                                    </div>

                                                    <div className="treeRow">
                                                        {order.children.length === 0 ? null : (
                                                            order.children.map((child, index) => (
                                                                <div key={index}>
                                                                    <div  >
                                                                        <div className="kingdomBoxHeader">
                                                                        <img
                                                                        className={`${getClassName(index)} ${child.user_name && child.user_name !== '' ? child.user_name : 'blur-mg'}`}
                                                                                src={
                                                                                    index === 0 ? advisorImage :
                                                                                    index === 1 ? generalImage :
                                                                                    index === 2 ? governerImage :
                                                                                    '' 
                                                                                }
                                                                                alt="Tree King"
                                                                            />
                                                                        </div>
                                                                        <h4>
                                                                            {index === 0 && 'ADVISOR'}
                                                                            {index === 1 && 'GENERAL'}
                                                                            {index === 2 && 'GOVERNER'}
                                                                            <br />
                                                                           
                                                                        </h4>
                                                                        <p> {(child.user_name && child.user_name !== '') ?  `( ${child.user_name} )` : ''} </p>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        )}
                                                    </div>


                                                </div>
                                                </div>
                                            ))
                                        )
                                    }
                                </Tab> */}
                                {/* <Tab eventKey="profile" title="Transaction History">
                                    <div className='buySellTable table-responsive'>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Transaction</th>
                                                    <th width="500">Date & Time</th>
                                                    <th width="500">Package name</th>
                                                    <th>Amount</th>
                                                    <th>Currency</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { 
                                                    transactions.length === 0 ? 
                                                    (
                                                        <p>No transactions found</p>
                                                    ) : 
                                                    (
                                                        transactions.map((trnx, index) => (
                                                            <tr key={trnx.id}>
                                                                <td className={ index%2===0 ? "greenColor" : "redColor" }> 
                                                                    <a href={SOLCAN_URL+(trnx.trnx)+(SOLCAN_CLUSTER)} target="_blank" rel="noopener noreferrer"  style={{ textDecoration: 'none' }}>
                                                                        {trnx.trnx.length > 25 ? `${trnx.trnx.slice(0, 25)}...` : trnx.trnx}
                                                                    </a>
                                                                </td>
                                                                <td className={ index%2===0 ? "greenColor" : "redColor" }>{trnx.created_at}</td>
                                                                <td className={ index%2===0 ? "greenColor" : "redColor" }>{trnx.product_name}</td>
                                                                <td className={ index%2===0 ? "greenColor" : "redColor" }>{trnx.product_price}</td>
                                                                <td className={ index%2===0 ? "greenColor" : "redColor" }>USDT</td>
                                                            </tr>
                                                        ))
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Tab> */}
                            {/* </Tabs> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>

        <NewsletterHome/>
        <Footer/>
    </>
  );
}

export default Profile;