// Filename - pages/about.js

import React from "react";

const About = () => {
	return (
		<div>
			<h1>
				GeeksforGeeks is a Computer Science portal
				for geeks.
			</h1>
		</div>
	);
};

export default About;
