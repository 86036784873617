import React from "react";
import './ConnectWallet.css';
import Header from "../../components/Header/Header";
import NewsletterHome from "../../components/NewsletterHome/NewsletterHome";
import Footer from "../../components/Footer/Footer";

import InnerBanner from "../../images/inner-banner.webp";

const ConnectWallet = () => {
	return (
		<>
            <Header/>
            <div className="innerPageBanner">
                <img src={InnerBanner} alt='Inner Page Banner'/>
                <h2>Connect Wallet</h2>
            </div>

            <section className='formSec'>
                <div className="container">
                    <div className="formBox">
                        <div className="ConnectWalletForm">
                            <h3>Connect <br/> your Wallet</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                            <form>
                                <div className="radioButton">
                                    <input id="radioone" type="radio" defaultValue="radioone" name="radiobtn" />
                                    <label htmlFor="radioone">
                                        <span className="buttonLable">Loram</span>
                                        <span className="form__radio-button"></span>
                                    </label>
                                </div>
                                <div className="radioButton">
                                    <input id="radiotwo" type="radio" defaultValue="radiotwo" name="radiobtn" />
                                    <label htmlFor="radiotwo">
                                        <span className="buttonLable">Loram</span>
                                        <span className="form__radio-button"></span>
                                    </label>
                                </div>
                                <div className="radioButton">
                                    <input id="radiothree" type="radio" defaultValue="radiothree" name="radiobtn" />
                                    <label htmlFor="radiothree">
                                        <span className="buttonLable">Loram</span>
                                        <span className="form__radio-button"></span>
                                    </label>
                                </div>
                                <button className="connectBtn">Connect Wallet</button>
                                <p className="walletPrivacyText">By clicking Allow Access, you athorize Lorem to use your infomation in accordance with its <a href="/">Privacy Policy.</a></p>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <NewsletterHome/>
            <Footer/>
        </>
	);
};

export default ConnectWallet;
