import React from 'react';
import "./ReferralUsers.css";

import UserTree from "../../images/user_tree.png";

const ReferralUsers = () => {
    return (
       <section className='ReferralUsers' id='ReferralUsers'>
            <div className="container">
                <div className='ReferralUsersTitle'>
                    <h3>Referral Users</h3>
                </div>
                <div className='UserTree'>
                    <img src={UserTree} alt='User Tree' />
                </div>
            </div>
       </section>
    );
};

export default ReferralUsers;