import React from 'react';

import "./HomeBanner.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'; 

import bannerCenterImage from "../../images/King-2.webp";
import bannerCenterImage2 from "../../images/banner-slide-two.webp";
import bannerCenterImage3 from "../../images/Banner-3-Bird 1.webp";
const HomeBanner = () => {
  
    return (
        <>
           
                <section className="homeBannerSec2">
                    <div className="asd">
                        <OwlCarousel className='owl-theme' loop margin={10} items={1} nav mouseDrag={false} touchDrag={true} autoplay={true}>
                            <div className="item oneSlide">
                                <div className="container">
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <div className='bannerLeftCol'>
                                                <h1 className='hideRisponsive'>Double Returns</h1>
                                                <h1 className='hideDextop'>Double Your</h1>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='bannerCenterCol'>
                                                <img src={bannerCenterImage} alt='Banner Center'/>
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className='bannerRightCol'>
                                                <h1 className='hideRisponsive'>Your Today!</h1>
                                                <h1 className='hideDextop'>Returns Today!</h1>
                                                <p>Join us on this thrilling adventure and become the ruler you were destined to be. Purchase your package now and start building your Realm!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item forSlidStyle">
                                <div className="container">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='bannerCenterCol bannerCenterColBefore '>
                                                <img src={bannerCenterImage2} alt='Banner Center'/>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='bannerLeftCol'>
                                                <h1>100% Money Back Gurantee
                                                </h1>
                                                <p>Invest confidently with our 100% money-back guarantee. If you're not satisfied within 60 days, we'll refund your investment, no questions asked.</p>
                                            </div>
                                        </div>
                                        {/* <div className='col-md-3'>
                                            <div className='bannerRightCol'>
                                                <h1>Your Today!</h1>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="item slideLast">
                                <div className="container">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='bannerCenterCol bannerCenterColBefore'>
                                                <img src={bannerCenterImage3} alt='Banner Center'/>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='bannerLeftCol'>
                                                <h1>Team Up, Double Up</h1>
                                                <p>Team up with three others to double your investment. Start your journey today with our exclusive packages.</p>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            
                        </OwlCarousel>
                    </div>
                </section>
           
        </>
    );
};

export default HomeBanner;
