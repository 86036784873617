/* eslint-disable jsx-a11y/anchor-is-valid */

import "../login/login.css";
import "../profile/profile.css";
import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../store/store";
import { toast } from "react-toastify";
import Header from "../../components/Header/Header";
import Logout from "../../components/Logout/Logout";
import LoginSidebar from "../../components/LoginSidebar/LoginSidebar";
import NewsletterHome from "../../components/NewsletterHome/NewsletterHome";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";
import InnerBanner from "../../images/inner-banner.webp";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import ClipboardImg from "../../images/clipboard-img.webp";
import {
  fetchActiveOrders,
  fetchCompletedOrders,
} from "../../services/OrderService";
import { FiEye, FiEyeOff } from 'react-icons/fi'; 
const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const SOLCAN_URL = process.env.REACT_APP_SOLCAN_URL;
const SOLCAN_CLUSTER = process.env.REACT_APP_SOLCAN_CLUSTER;

function ChnagePassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNo, setPhoneNo] = useState(null);
  const [gender, setGender] = useState(null);
  const [dob, setDob] = useState(null);
  const [oldPassword, setOldPassword] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState(null);
  const [name, setName] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [userId, setUserId] = useState(null);
  const [path, setPath] = useState(true);
  const [orders, setOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [transactions, setTransactions] = useState([]);

  // const walletStatus = useStore((state) => state.walletStatus);
  // const updateWalletStatus = useStore((state) => state.setWalletStatus);

  // const defaultAccount = useStore((state) => state.defaultAccount);
  // const updateDefaultAccount = useStore((state) => state.setDefaultAccount);
  // const navFlag = useStore((state) => state.navFlag);
  // const updateNavFlag = useStore((state) => state.setNavFlag);

  useEffect(() => {
    if(!localStorage.getItem("token")){
      navigate("/account");
     }
    setName(localStorage.getItem("name"));
    setType(localStorage.getItem('type'));
    setFirstName(localStorage.getItem("firstname"));
    setLastName(localStorage.getItem("lastname"));
    setEmail(localStorage.getItem("email"));
    setPhoneNo(localStorage.getItem("phone_no"));
    setDob(localStorage.getItem("dob"));
    setGender(localStorage.getItem("gender"));
    setUserId(localStorage.getItem("userId"));
    setProfilePic(localStorage.getItem("profilePic"));
    

    // const fetchData = async () => {
    //   const orderData = await fetchActiveOrders();
    //   setOrders(orderData.data);

    //   const completedOrderData = await fetchCompletedOrders();
    //   setCompletedOrders(completedOrderData.data);
    // };
    // if (localStorage.getItem("name")) {
    //   fetchData();
    // }
    // if(!navFlag){
    //    // navigate("/account");
    //   }
  }, []);

  // const fetchDataForLogin = async () => {
  //   const orderData = await fetchActiveOrders();
  //   setOrders(orderData.data);

  // };

  // const handleLogout = async () => {
  //   // console.log(localStorage.getItem('token'))
  //   try {
  //     const requestOptions = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //     };

  //     const response = await fetch(APP_BASE_URL + "/logout", requestOptions);
  //     const data = await response.json();
  //     // console.log('Response:', data);
  //     unSyncDetail();

  //     // Disconnect Wallet
  //     updateWalletStatus(null);
  //     updateDefaultAccount(null);
  //     updateNavFlag(null);
  //     navigate("/account");
  //     toast.success("Logged out successfully");
  //     // Redirect or update state to indicate successful login
  //   } catch (error) {
  //     toast.error("Error:", error);
  //     unSyncDetail();
  //     // Handle error, e.g., display an error message to the user
  //   }
  // };

  const [copied, setCopied] = useState(false); // State to track if the referral code is copied

  const copyReferralCode = (code) => {
    navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
    alert("Referral code copied to clipboard!");
  };



  const syncDetail = (data) => {
    localStorage.setItem("name", data.name);
    localStorage.setItem('type', data.type);
    localStorage.setItem("firstname", data.firstname);
    localStorage.setItem("lastname", data.lastname);
    localStorage.setItem("email", data.email);
    localStorage.setItem("phone_no", data.phone_no);
    localStorage.setItem("dob", data.dob);
    localStorage.setItem("gender", data.gender);
    localStorage.setItem("userId", data.id);
    localStorage.setItem("profilePic", data.profile_pic);

    setName(data.name);
    setType(data.type);
    setFirstName(data.firstname);
    setLastName(data.lastname);
    setEmail(data.email);
    setPhoneNo(data.phone_no);
    setDob(data.dob);
    setGender(data.gender);
    setUserId(data.id);
    setProfilePic(data.profile_pic);
  };





  const handlePassword = async (event) => {
    event.preventDefault();

    if (!oldPassword) {
      toast.error('Please Enter Old Password');
      return;
    }
    
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;
    if (!password && !passwordRegex.test(password)) {
      toast.error('Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.');
      return;
    }
    
    if (!passwordConfirmation) {
      toast.error('Please Enter Confirm Password');
      return;
    }

    setLoading(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          id: userId,
          password: password,
          password_confirmation: passwordConfirmation,
          old_password: oldPassword,
        }),
      };

      const response = await fetch(
        APP_BASE_URL + "/updatePassword",
        requestOptions
      );

      const data = await response.json();
      // console.log('Response:', data);
      if (data.success) {
        // Assuming data contains the token and name

        syncDetail(data.data);
        toast.success("Password updated successfully");
      } else {
        if (data.data.error) {
          toast.error(data.data.error);
        } else {
          let allErrors = "";
          for (const property in data.data) {
            allErrors = " " + data.data[property] + ".";
            // console.log(`${property}: ${data.data[property]}`);
          }
          toast.error(allErrors);
        }
      }

      // Redirect or update state to indicate successful login
    } catch (error) {
      toast.error("Error:", error);
      // Handle error, e.g., display an error message to the user
    }finally {
      setLoading(false); 
    }
  };

  const toggleForm = () => {
    setPath(!path);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <Header />
        {
          (loading)
          ? 
            <Loader />
          :
          null
        }
        <div className="innerPageBanner">
            <img src={InnerBanner} alt="Inner Page Banner" />
            <h2>Profile</h2>
        </div>
        <section className="profileSec">
            <Container>
                <Row>
                    <Col sm={3} className="column-three">
                    <LoginSidebar Userprofile={profilePic} Profilename={name} id={userId}/>
                    <Logout />
                    </Col>
                    <Col sm={9} className="column-nine">
                    <div className="tabTitle">
                        <h4>Change Password</h4>
                        <div className="personalInfoForm">
                        <form onSubmit={handlePassword}>
                            <div className="personalForm">
                            <label>Old Password</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Enter Password"
                                // value={password}
                                onChange={(e) =>
                                setOldPassword(e.target.value)
                                }
                            />
                            <Button
                              className="signup_btn"
                              variant="outline-secondary"
                              onClick={togglePasswordVisibility}
                              >
                              {showPassword ? <FiEyeOff /> : <FiEye />}
                            </Button>
                            </div>
                            <div className="personalForm">
                            <label>New Password</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Enter Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button
                              className="signup_btn"
                              variant="outline-secondary"
                              onClick={togglePasswordVisibility}
                          >
                              {showPassword ? <FiEyeOff /> : <FiEye />}
                          </Button>
                            </div>
                            <div className="personalForm">
                            <label>Confirm Password</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Enter Password"
                                value={passwordConfirmation}
                                onChange={(e) =>
                                setPasswordConfirmation(e.target.value)
                                }
                            />
                            <Button
                              className="signup_btn"
                              variant="outline-secondary"
                              onClick={togglePasswordVisibility}
                              >
                              {showPassword ? <FiEyeOff /> : <FiEye />}
                            </Button>
                            </div>
                            <button type="submit" className="connectBtn">
                            Save
                            </button>
                        </form>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Container>
        </section>

      <NewsletterHome />
      <Footer />
    </>
  );
}

export default ChnagePassword;
