import "./WalletWithdraw.css";
import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { useStore } from '../../store/store';
import { formatValue } from '../../services/MainService';
import Loader from "../../components/Loader/Loader";

import ClipboardImg from "../../images/clipboard-img.webp";
import refreshIcon from "../../images/loop.png";
import UstdIcon from "../../images/USDT-icon.png";
import TrxIcon from "../../images/trk-icon.png";
import { toast } from "react-toastify";
function WalletInner() {
    
    const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const APP_TRON_URL = process.env.REACT_APP_TRON_URL;
    const WALLET_APP_FROM_ADDRESS = process.env.REACT_APP_FROM_ADDRESS;
    const [accountAddress, setAccountAddress] = useState(localStorage.getItem("accountAddress") || "");
    const [loading, setLoading] = useState(false);
   
    const { userwalletamount, setUserWalletAmount } = useStore(state => ({
        userwalletamount: state.userwalletamount,
        setUserWalletAmount: state.setUserWalletAmount,
    }));
    const { userTron, setUserTron } = useStore(state => ({
      userTron: state.userTron,
      setUserTron: state.setUserTron,
  }));
    const [transactions, setTransactions] = useState([]);
    const [lastClickTime, setLastClickTime] = useState(() => {
        // Retrieve the last click time from session storage if available
        const storedTime = sessionStorage.getItem('lastClickTime');
        return storedTime ? new Date(storedTime) : null;
      });
    const handleCreatWallet = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
          const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
          };
            const response = await fetch(APP_BASE_URL + "/createWallet", requestOptions);
            const data = await response.json();
            
            if (data.success == true) {
                localStorage.setItem("accountAddress", data.data.account_address);
                setAccountAddress(data.data.account_address);
                //setShowWallet(true);
                toast.success(data.message);
            } else if (data.success == false) {
              toast.error(data.message);
            }
            
        } catch (error) {
          
          toast.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };
    const truncateAddress = (address) => {
      return `${address.slice(0, 5)}...${address.slice(-4)}`;
    };
    useEffect(() => {
        // Cleanup session storage on component unmount (if necessary)
        return () => {
          sessionStorage.removeItem('lastClickTime');
        };
      }, []);
    
      const handleClick = () => {
        const currentTime = new Date();
        
        if (lastClickTime) {
          const timeDifference = currentTime - lastClickTime;
          const fiveMinutesInMs = 1 * 60 * 1000; // 5 minutes in milliseconds
    
          if (timeDifference < fiveMinutesInMs) {
            toast.error('Please wait 2 minutes before fetching the amount again.');
            return;
          }
        }
    
        // Update last click time
        setLastClickTime(currentTime);
        sessionStorage.setItem('lastClickTime', currentTime.toISOString());
    
        fetchUserWalletAmount();
        fetchTransactions();
      };

   // Fetch USDT USer Wallet
  const fetchUserWalletAmount = async () => {
    setLoading(true);
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      const response = await fetch(APP_BASE_URL + "/getUserWallet", requestOptions);
      const data = await response.json();
      
      if (data.success) {
        // Convert amount to a number if needed
       // const amount = parseFloat(data.data.amount);
        localStorage.setItem("walletamount", data.data.amount);
        setUserWalletAmount(data.data.amount);
        fetchTronBalance();
        //toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    //fetchUserWalletAmount();
  }, []); 

    const [copytrnx, setCopyTrnx] = useState(false);
    const copyTrnx = (code) => {
        navigator.clipboard.writeText(code);
        setCopyTrnx(true);
        setTimeout(() => setCopyTrnx(false), 2000);
        toast.success("Address code copied to clipboard!");
    };
  
     // Fetch transaction data
  const fetchTransactions = async () => {

    setLoading(true);
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      };
      const response = await fetch(APP_BASE_URL + '/getAllTransaction', requestOptions);
      const data = await response.json();

      if (data.success) {
        if(data.data){
            setTransactions(data.data.original.data);
           // toast.success(data.message);
        }
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error('Error: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchTronBalance = async () => {

    setLoading(true);
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      };
      const response = await fetch(APP_BASE_URL + '/getTronBalance', requestOptions);
      const data = await response.json();

      if (data.success) {
        if(data.data){
          localStorage.setItem("wallettronamount", data.data.amount);
          setUserTron(data.data.balance);
           // toast.success(data.message);
        }
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error('Error: ' + error.message);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchUserWalletAmount();
    fetchTransactions();
  }, []);
  const copyToClipboard = (address) => {
    navigator.clipboard.writeText(address).then(() => {
      toast.success('Address copied to clipboard!');
    }, (err) => {
      toast.error('Failed to copy address: ', err);
    });
  };
    return (
        <>
        {
           (loading)
           ? 
            <Loader />
          :
          null
        }
       
            <div className="current_balance">
                <h3>Current Balance</h3>
                <div className="currency-box-main">
                  <div className="currency_box">
                    <h2>USDT</h2> <img src={UstdIcon}/>
                    
                    <h3>{formatValue(userwalletamount,0)} </h3>
                  </div>
                  <div className="currency_box">
                    <h2>TRX</h2> <img src={TrxIcon}/>
                    <h3>{formatValue(userTron,0)} </h3>
                  </div>
                </div>
                
                
                <button onClick={handleClick}  className="fatch_amount_btn">Fetch amount</button>
            </div>
             
            {!accountAddress && 
             <button className="create_wallet_btn" onClick={handleCreatWallet}>Create wallet</button>
            }
         {accountAddress && 
            <div className="wallet_address" >
                <h3>Wallet Address</h3>
                <div className="address_field">
                    <p>{accountAddress}</p>
                    <button onClick={() => copyTrnx(accountAddress) } className="copyButton"><img src={ClipboardImg} alt="Clipboard" />Copy</button>
                </div>
            </div>
        }
            <div className="wallet_transaction">
                <h3>Transaction History</h3>
                <div className="transaction_table table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>From</th>
                                <th>To</th>
                                <th>Amount</th>
                                <th>View Transaction</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                        {transactions.slice().reverse().map((transaction) => {
                               
                                //const fromClass = transaction.from === accountAddress ? 'redColor' : 'greenColor';
                                const toClass = transaction.to === accountAddress ? 'greenColor' : 'redColor';

                                return (
                                    <tr key={transaction.transaction_id}>
                                    <td 
                                    onClick={() => copyToClipboard(transaction.from)} 
                                    style={{ cursor: 'pointer' }}
                                    className={toClass}>{transaction.from === WALLET_APP_FROM_ADDRESS ? 'ROR': truncateAddress(transaction.from)}</td>
                                    <td
                                    onClick={() => copyToClipboard(transaction.to)} 
                                    style={{ cursor: 'pointer' }}
                                    className={toClass}>{transaction.to === WALLET_APP_FROM_ADDRESS ? 'ROR': truncateAddress(transaction.to)}</td>
                                    <td className={toClass}>
                                        {formatValue(transaction.value, transaction.token_info.decimals)}
                                    </td>
                                    <td className="align_center">
                                        <Link
                                        target="_blank"
                                        className="transaction_view_btn"
                                        to={`${APP_TRON_URL}transaction/${transaction.transaction_id}`}
                                        >
                                        View
                                        </Link>
                                    </td>
                                    </tr>
                                );
                                })}
                        </tbody>
                       
                    </table>
                </div>
            </div>
        </>
    );
}
export default WalletInner;