import { create } from 'zustand'

const useStore = create((set) => ({
    walletStatus: false,
    setWalletStatus: (isActive) => set(() => ({ walletStatus: isActive })),
    
    defaultAccount: false,
    setDefaultAccount: (isActive) => set(() => ({ defaultAccount: isActive })),

    phantomWallet: null,
    setPhantomWallet: (param) => set(() => ({ phantomWallet: param })),

    etherPrice: 11,
    setEtherPrice: (price) => set(() => ({ etherPrice: price })),

    solPrice: 0,
    setSolPrice: (prc) => set(() => ({ solPrice: prc })),
    
    starter: true,
    setStarter: (flag) => set(() => ({ starter: flag })),

    navFlag: null,
    setNavFlag: (flag) => set(() => ({ navFlag: flag })),

    userwalletamount: localStorage.getItem('walletamount') || 0,
    setUserWalletAmount: (amount) => {
        localStorage.setItem('walletamount', amount);
        set({ userwalletamount: amount });
    },

    userTron: localStorage.getItem('wallettronamount') || 0,
    setUserTron: (amounts) => {
        localStorage.setItem('wallettronamount', amounts);
        set({ userTron: amounts });
    },
    
}))

export  {useStore};