/* eslint-disable jsx-a11y/anchor-is-valid */

import "./login.css";
import "../profile/profile.css";
import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../store/store";
import { toast } from "react-toastify";

import Header from "../../components/Header/Header";
import LoginSidebar from "../../components/LoginSidebar/LoginSidebar";
import MyAccount from "../account/account";
import NewsletterHome from "../../components/NewsletterHome/NewsletterHome";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";
import InnerBanner from "../../images/inner-banner.webp";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import ClipboardImg from "../../images/clipboard-img.webp";
import {
  fetchActiveOrders,
  fetchCompletedOrders,
} from "../../services/OrderService";
import { FiEye, FiEyeOff } from 'react-icons/fi'; 
const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const SOLCAN_URL = process.env.REACT_APP_SOLCAN_URL;
const SOLCAN_CLUSTER = process.env.REACT_APP_SOLCAN_CLUSTER;

function Login() {
  
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/my_account");
    }
  }, [navigate]);

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNo, setPhoneNo] = useState(null);
  const [gender, setGender] = useState(null);
  const [dob, setDob] = useState(null);
  const [oldPassword, setOldPassword] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState(null);
  const [name, setName] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [userId, setUserId] = useState(null);
  const [path, setPath] = useState(true);
  const [orders, setOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);
  const [walletamount, setWalletAmount] = useState(false);

  const validateEmail = (email) => {
    if (!email) {
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    if (!emailRegex.test(email)) {
      return false;
    }
    const localPart = email.split('@')[0];

    if (email === 'example@example.com' || localPart.includes('example')) {
      return false;
    }

    return true;
  };
  // const walletStatus = useStore((state) => state.walletStatus);
  // const updateWalletStatus = useStore((state) => state.setWalletStatus);

  // const defaultAccount = useStore((state) => state.defaultAccount);
  // const navFlag = useStore((state) => state.navFlag);
  // const updateNavFlag = useStore((state) => state.setNavFlag);
  // const updateDefaultAccount = useStore((state) => state.setDefaultAccount);

  // useEffect(() => {
  
  //   updateNavFlag(localStorage.getItem("name"));
  //   setName(localStorage.getItem("name"));
  //   setType(localStorage.getItem('type'));
  //   setFirstName(localStorage.getItem("firstname"));
  //   setLastName(localStorage.getItem("lastname"));
  //   setEmail(localStorage.getItem("email"));
  //   setPhoneNo(localStorage.getItem("phone_no"));
  //   setDob(localStorage.getItem("dob"));
  //   setGender(localStorage.getItem("gender"));
  //   setUserId(localStorage.getItem("userId"));
  //   setProfilePic(localStorage.getItem("profilePic"));

  //   const fetchData = async () => {
  //     const orderData = await fetchActiveOrders();
  //     setOrders(orderData.data);

  //     const completedOrderData = await fetchCompletedOrders();
  //     setCompletedOrders(completedOrderData.data);
  //   };
  //   if (localStorage.getItem("name")) {
  //     fetchData();
  //   }
  // }, []);

  const fetchDataForLogin = async () => {
    const orderData = await fetchActiveOrders();
    setOrders(orderData.data);

  };

  // const handleLogout = async () => {
  //   // console.log(localStorage.getItem('token'))
  //   try {
  //     const requestOptions = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //     };

  //     const response = await fetch(APP_BASE_URL + "/logout", requestOptions);
  //     const data = await response.json();
  //     // console.log('Response:', data);
  //     unSyncDetail();

  //     // Disconnect Wallet
  //     updateWalletStatus(null);
  //     updateDefaultAccount(null);
  //     navigate("/account");
  //     toast.success("Logged out successfully");
  //     // Redirect or update state to indicate successful login
  //   } catch (error) {
  //     toast.error("Error:", error);
  //     unSyncDetail();
  //     // Handle error, e.g., display an error message to the user
  //   }
  // };

  const [copied, setCopied] = useState(false); // State to track if the referral code is copied

  const copyReferralCode = (code) => {
    navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
    alert("Referral code copied to clipboard!");
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    
    if (!validateEmail(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }
    
    
    if (!email || !password) {
      setLoading(false); // Hide loader
      toast.error("Email and password are required."); // Set an error message
      return; // Stop the form submission
    }
    setLoading(true); // Show loader
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    };

    const response = await fetch(APP_BASE_URL + "/login", requestOptions);

    const data = await response.json();
   // console.log(data);
    if (data.success) {
      // Assuming data contains the token and name
      localStorage.setItem("token", data.data.token);
      // console.log(data.data);
      syncDetail(data.data);
      fetchDataForLogin();
      navigate("/my_account");
      setLoading(false);
      toast.success("Logged in successfully");
    } else {
      setLoading(false); 
      if(data.data.email){
        toast.error('The email must be a valid email address.');
      }else{
        toast.error(data.data.error);
      }
      
    }

    // Redirect or update state to indicate successful login
  };

  const syncDetail = (data) => {
    localStorage.setItem("name", data.name);
    localStorage.setItem('type', data.type);
    localStorage.setItem("firstname", data.firstname);
    localStorage.setItem("lastname", data.lastname);
    localStorage.setItem("email", data.email);
    localStorage.setItem("phone_no", data.phone_no);
    localStorage.setItem("dob", data.dob);
    localStorage.setItem("gender", data.gender);
    localStorage.setItem("userId", data.id);
    localStorage.setItem("profilePic", data.profile_pic);
    localStorage.setItem("accountAddress", data.walletAddress);
    localStorage.setItem("walletamount", data.walletAmount);
    //updateNavFlag(data.name);
    setName(data.name);
    setType(data.type);
    setFirstName(data.firstname);
    setLastName(data.lastname);
    setEmail(data.email);
    setPhoneNo(data.phone_no);
    setDob(data.dob);
    setGender(data.gender);
    setUserId(data.id);
    setProfilePic(data.profile_pic);
  };

  // const unSyncDetail = () => {
  //   // remove token
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("fullname");
  //   localStorage.removeItem('type');
  //   localStorage.removeItem("name");
  //   localStorage.removeItem("firstname");
  //   localStorage.removeItem("lastname");
  //   localStorage.removeItem("email");
  //   localStorage.removeItem("phone_no");
  //   localStorage.removeItem("dob");
  //   localStorage.removeItem("gender");
  //   localStorage.removeItem("userId");
  //   localStorage.removeItem("profilePic");

  //   updateNavFlag(null);
  //   setName(null);
  //   setType(null);
  //   setFirstName(null);
  //   setLastName(null);
  //   setEmail(null);
  //   setPhoneNo(null);
  //   setDob(null);
  //   setGender(null);
  //   setUserId(null);
  //   setProfilePic(null);
  // };
 const handleClose = () => {
    setShow(false)
  };
  const handleRegister = async (event) => {
    event.preventDefault();

    const errors = {};
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;


    if (!firstName) {
      errors.firstName = true;
    }
    if (!lastName) {
      errors.lastName = true;
    }
    if (!email) {
      errors.email = true;
    } else if (!validateEmail(email)) {
      errors.email = true;
      toast.error('Please enter a valid email address.');
    }
    if (!phoneNo) {
      errors.phoneNo = true;
    }
    if (!password) {
      errors.password = true;
    } else if (!passwordRegex.test(password)) {
      errors.password = true;
      toast.error('Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.');
    }
    if (!passwordConfirmation) {
      errors.passwordConfirmation = true;
    }
    if (password && passwordConfirmation && password !== passwordConfirmation) {
      errors.password = false;
      errors.passwordConfirmation = false;
      toast.error('Passwords do not match.');
    }
    
    if (Object.keys(errors).length > 0) {
    // Show errors and set red border
    setErrors(errors);
    //toast.error('Highlighted Feilds are required');
    return;
  }

    setLoading(true);
    try {
      // console.log("email", email);
      // console.log("password", password);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstname: firstName,
          lastname: lastName,
          email: email,
          phone_no: phoneNo,
          password: password,
          password_confirmation: passwordConfirmation,
        }),
      };

      const response = await fetch(APP_BASE_URL + "/register", requestOptions);

      const data = await response.json();
      // console.log('Response:', data);
      if (data.success) {
        // Assuming data contains the token and name

        // await syncDetail(data.data);
       //toast.success("Registered successfully");
        setShow(true)
        setPath(!path);
        
      } else {
        if (data.data.error) {
          toast.error(data.data.error);
        } else {
          let allErrors = "";
          for (const property in data.data) {
            allErrors = " " + data.data[property] + ".";
            // console.log(`${property}: ${data.data[property]}`);
          }
          toast.error(allErrors);
        }
      }

      // Redirect or update state to indicate successful login
    } catch (error) {
      toast.error("Error:", error);
      // Handle error, e.g., display an error message to the user
    }finally {
      setLoading(false); 
    }
  };

  // const handleProfile = async (event) => {
  //   event.preventDefault();
  //   try {
  //     // console.log("email", email);
  //     // console.log("password", password);
  //     const requestOptions = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //       body: JSON.stringify({
  //         id: userId,
  //         firstname: firstName,
  //         lastname: lastName,
  //         email: email,
  //         phone_no: phoneNo,
  //         dob: dob,
  //         gender: gender,
  //       }),
  //     };

  //     const response = await fetch(APP_BASE_URL + "/updateProfile", requestOptions);

  //     const data = await response.json();
  //     // console.log('Response:', data);
  //     if (data.success) {
  //       // Assuming data contains the token and name

  //       syncDetail(data.data);
  //       toast.success("Profile updated successfully");
  //     } else {
  //       if (data.data.error) {
  //         toast.error(data.data.error);
  //       } else {
  //         let allErrors = "";
  //         for (const property in data.data) {
  //           allErrors = " " + data.data[property] + ".";
  //           // console.log(`${property}: ${data.data[property]}`);
  //         }
  //         toast.error(allErrors);
  //       }
  //     }

  //     // Redirect or update state to indicate successful login
  //   } catch (e) {
  //     toast.error("Error:", e);
  //     // Handle error, e.g., display an error message to the user
  //   }
  // };

  // const handlePassword = async (event) => {
  //   event.preventDefault();
  //   try {
  //     const requestOptions = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //       body: JSON.stringify({
  //         id: userId,
  //         password: password,
  //         password_confirmation: passwordConfirmation,
  //         old_password: oldPassword,
  //       }),
  //     };

  //     const response = await fetch(
  //       APP_BASE_URL + "/updatePassword",
  //       requestOptions
  //     );

  //     const data = await response.json();
  //     // console.log('Response:', data);
  //     if (data.success) {
  //       // Assuming data contains the token and name

  //       syncDetail(data.data);
  //       toast.success("Password updated successfully");
  //     } else {
  //       if (data.data.error) {
  //         toast.error(data.data.error);
  //       } else {
  //         let allErrors = "";
  //         for (const property in data.data) {
  //           allErrors = " " + data.data[property] + ".";
  //           // console.log(`${property}: ${data.data[property]}`);
  //         }
  //         toast.error(allErrors);
  //       }
  //     }

  //     // Redirect or update state to indicate successful login
  //   } catch (error) {
  //     toast.error("Error:", error);
  //     // Handle error, e.g., display an error message to the user
  //   }
  // };

  const toggleForm = () => {
    setPath(!path);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <Header />
      {
           (loading)
           ? 
            <Loader />
          :
          null
        }
      {
        path ? (
          <>
            <div className="innerPageBanner">
              <img src={InnerBanner} alt="Inner Page Banner" />
              <h2>Log in</h2>
            </div>
            <section className="formSec">
              <div className="container">
                <div className="formBox">
                  <div className="loginForm">
                    <h3>
                      Log in to <br /> make money Again!
                    </h3>
                    {/* <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </p> */}
                    <Form onSubmit={handleLogin}>
                      <Form.Control
                        type="email"
                        id="inputEmail"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      <Form.Control
                         type={showPassword ? 'text' : 'password'}
                        id="inputPassword"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        
                      />
                      <Button
                          className="signup_btn"
                          variant="outline-secondary"
                          onClick={togglePasswordVisibility}
                      >
                          {showPassword ? <FiEyeOff /> : <FiEye />}
                      </Button>
                      <Link to="/forget_password" className="forgetPass" >Forget Password?</Link>

                      <Button type="submit" className="loginBtn">
                        Log in
                      </Button>
                      <a className="formSwitch" onClick={toggleForm}>
                        {path
                          ? "Create a New Account"
                          : "If you’ve an account then Login"}
                      </a>
                    </Form>
                  </div>
                </div>
              </div>
            </section>
            <Modal show={show} onHide={handleClose} className='referralModal font-z-thank modal-dialog-centered'>
            <Modal.Header closeButton>
                <Modal.Title>Thank you for signing up with Realm of Royals!</Modal.Title>
                
                <p>Please verify your email address to complete your registration.</p>
            </Modal.Header>
            
            

          </Modal>
          </>
        ) : (
          <>
            <div className="innerPageBanner">
              <img src={InnerBanner} alt="Inner Page Banner" />
              <h2>Sign up</h2>
            </div>
            <section className="formSec">
              <div className="container">
                <div className="formBox">
                  <div className="loginForm">
                    <h3>
                      Sign up to <br /> make money Double!
                    </h3>
                    {/* <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </p> */}
                    <Form onSubmit={handleRegister}>
                      <Form.Control
                        type="text"
                        id="inputName"
                        placeholder="First name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        style={{ borderColor: errors.firstName ? 'red' : '' }}
                      />
                      <Form.Control
                        type="text"
                        id="inputName"
                        placeholder="Last name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        style={{ borderColor: errors.lastName ? 'red' : '' }}
                      />

                      <Form.Control
                        type="email"
                        id="inputEmail"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ borderColor: errors.email ? 'red' : '' }}
                      />

                      <Form.Control
                        type="number"
                        id="inputPhone"
                        placeholder="+1 123 456 7890"
                        value={phoneNo}
                        onChange={(e) => setPhoneNo(e.target.value)}
                        style={{ borderColor: errors.phoneNo ? 'red' : '' }}
                      />
                      
                      <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        id="inputPassword"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ borderColor: errors.password ? 'red' : '' }}
                      />
                      <Button
                          className="signup_btn"
                          variant="outline-secondary"
                          onClick={togglePasswordVisibility}
                      >
                          {showPassword ? <FiEyeOff /> : <FiEye />}
                      </Button>
                      <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        id="inputConfirmPassword"
                        placeholder="Confirm Password"
                        value={passwordConfirmation}
                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                        style={{ borderColor: errors.passwordConfirmation ? 'red' : '' }}
                      />
                        <Button
                            className="signup_btn"
                            variant="outline-secondary"
                            onClick={togglePasswordVisibility}
                        >
                            {showPassword ? <FiEyeOff /> : <FiEye />}
                        </Button>
                      <Button type="submit" className="loginBtn">
                        Create Account
                      </Button>
                     
                      <a className="formSwitch" onClick={toggleForm}>
                        {path
                          ? "Create a New Account"
                          : "If you’ve an account then Login"}
                      </a>
                    </Form>
                  </div>
                </div>
              </div>
            </section>
            
          </>
        )
      }

      <NewsletterHome />
      <Footer />
    </>
  );
}

export default Login;
