import React from 'react';
import "./RoadMap.css";

import roadMap from "../../images/rodamap.png";

const RoadMap = () => {
    return (
       <section className='roadMapSec' id='roadmap'>
            <div className='container'>
                <div className='secTitle text-center'>
                    <h1>Roadmap</h1>
                    <p>Embark on an exciting journey with our roadmap to success. Explore the milestones that will shape the future of our project and revolutionize your gaming experience.</p>
                </div>
                <div className='roadmapMain'>
                    <div className='roadmapLeftCol'>
                        <div className='roadmapText'>
                            <h3>Realm Estate</h3>
                            <p>Realm Estate's launch introduces users to acquiring virtual Realms and investment opportunities, marking the beginning of our platform's innovative journey.</p>
                        </div>
                        <div className='roadmapText'>
                            <h3>Mobile App</h3>
                            <p>Bringing the game to your fingertips with a dedicated mobile application.</p>
                        </div>
                        <div className='roadmapText'>
                            <h3>Stacking and Reward</h3>
                            <p>With staking rewards and comprehensive reward programs, Realm of Royals aims to enhance user engagement and ensure ecosystem stability.</p>
                        </div>
                        <div className='roadmapText'>
                            <h3>Launch GAME</h3>
                            <p>The grand launch of our game, offering a rich and immersive experience for players worldwide.</p>
                        </div>
                    </div>
                    <div className='roadmapCenterCol'>
                        <img src={roadMap} alt='Roadmap' />
                    </div>
                    <div className='roadmapRightCol'>
                        <div className='roadmapText'>
                            <h3>Own Wallet</h3>
                            <p>Implementing blockchain technology to ensure transparency and security in all transactions.</p>
                        </div>
                        <div className='roadmapText'>
                            <h3>Token Launch</h3>
                            <p>Launching our native token to fuel the in-game economy and provide rewards.</p>
                        </div>
                        <div className='roadmapText'>
                            <h3>NFT <span>(Character & Assets)</span></h3>
                            <p>Unlocking the potential of non-fungible tokens for unique in-game items and collectibles.</p>
                        </div>
                    </div>
                    <div className="forResMap">
                        <div className="row">
                            <div className="col-md-12">
                                <div className='roadmapText'>
                                    <h3>Realm Estate</h3>
                                    <p>Realm Estate's launch introduces users to acquiring virtual Realms and investment opportunities, marking the beginning of our platform's innovative journey.</p>
                                </div>
                                <div className='roadmapText'>
                                    <h3>Own Wallet</h3>
                                    <p>Implementing blockchain technology to ensure transparency and security in all transactions.</p>
                                </div>
                                <div className='roadmapText'>
                                    <h3>Mobile App</h3>
                                    <p>Bringing the game to your fingertips with a dedicated mobile application.</p>
                                </div>
                                <div className='roadmapText'>
                                    <h3>Token Launch</h3>
                                    <p>Launching our native token to fuel the in-game economy and provide rewards.</p>
                                </div>
                                 <div className='roadmapText'>
                                    <h3>Stacking and Reward</h3>
                                    <p>With staking rewards and comprehensive reward programs, Realm of Royals aims to enhance user engagement and ensure ecosystem stability.</p>
                                </div>
                                    <div className='roadmapText'>
                                    <h3>NFT <span>(Character & Assets)</span></h3>
                                    <p>Unlocking the potential of non-fungible tokens for unique in-game items and collectibles.</p>
                                </div>
                                <div className='roadmapText'>
                                    <h3>Launch GAME</h3>
                                    <p>The grand launch of our game, offering a rich and immersive experience for players worldwide.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default RoadMap;