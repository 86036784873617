import "./WalletWithdraw.css";
import React, {useState, useEffect} from "react";
import { Form, Button } from "react-bootstrap";
import { useStore } from '../../store/store';
import { formatValue, sendWalletAmount, checkTrnxInfo} from '../../services/MainService';
import Loader from "../../components/Loader/Loader";
import { toast } from "react-toastify";

import UstdIcon from "../../images/USDT-icon.png";
import TrxIcon from "../../images/trk-icon.png";

function WithdrawInner() {
    const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = useState(false);
   
    const [toaddress, setAddressTo] = useState(null);
    const [withdrawamount, setWithdrawAmount] = useState(null);
    const { userwalletamount, setUserWalletAmount } = useStore(state => ({
      userwalletamount: state.userwalletamount,
      setUserWalletAmount: state.setUserWalletAmount,
  }));
  const { userTron, setUserTron } = useStore(state => ({
    userTron: state.userTron,
    setUserTron: state.setUserTron,
}));
    const [accountAddress, setAccountAddress] = useState(localStorage.getItem("accountAddress") || "");

      const sendwithdrawl = async (e) => {
        
        e.preventDefault();
        if (!toaddress || !withdrawamount) {
            setLoading(false); // Hide loader
            toast.error("wallet address and amount are required."); // Set an error message
            return; // Stop the form submission
          }
        setLoading(true);
        try {
            
            let records = await sendWalletAmount(toaddress, withdrawamount);
            
            if(records.success){
                
                let result = await checkTrnxInfo(records.txId);
                console.log(result);
                if(result.contractRet == 'SUCCESS'){
                    setWithdrawAmount('');
                    setAddressTo('');
                    toast.success('withdrawal request success');
                }else{
                    toast.error(result.contractRet);
                }
             }
        } catch (error) {
            console.error('Error sending transaction:', error);
          
          }finally {
            setLoading(false); 
          }

      };
     
    return (
        <>
        {
           (loading)
           ? 
            <Loader />
          :
          null
        }
        <div className="row">
            <div className="col-md-12">
                <h2 className="withdrawamount_title">Current Balance</h2>
                <div className="currency-box-main">
                  <div className="currency_box">
                    <h2>USDT</h2> <img src={UstdIcon}/>
                    <h3>{formatValue(userwalletamount,0)} </h3>
                  </div>
                  <div className="currency_box">
                    <h2>TRX</h2> <img src={TrxIcon}/>
                    <h3>{formatValue(userTron,0)} </h3>
                  </div>
                  
                </div>
                <div className="withdraw_main">
                    <h3>TRC-20 Wallet address</h3>
                </div>
                <div className="withdraw_form">
                    
                        <div class="personalForm fromMain">
                            <label>From</label>
                           
                            <input type="text" placeholder=""  value={accountAddress}/>
                        </div>
                        <Form onSubmit={sendwithdrawl}>
                            <div class="personalForm fromTwo">
                                <label>To</label>
                                <input type="text" placeholder="Enter Send Wallet Address" value={toaddress} onChange={(e) => setAddressTo(e.target.value)} />
                            </div>
                            <div class="personalForm fromTwo fromAmount">
                                <label>Amount</label>{withdrawamount}
                                <input type="number" placeholder="Enter Amount" value={withdrawamount}  onChange={(e) => setWithdrawAmount(e.target.value)} />
                                <button type="submit" class="withdrawBtn">Withdraw</button>
                            </div>
                            
                        </Form>
                </div>
                {/* <div className="withdrawal_status">
                    <p>Withdrawal amount USDT 50</p>
                    <span>Processing</span>
                </div> */}
            </div>
            
        </div>
        </>
    );
}
export default WithdrawInner;