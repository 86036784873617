import React from "react";
import './PrivacyPolicy.css';
import Header from "../../components/Header/Header";
import NewsletterHome from "../../components/NewsletterHome/NewsletterHome";
import Footer from "../../components/Footer/Footer";

import InnerBanner from "../../images/inner-banner.webp";

const PrivacyPolicy = () => {
	return (
		<>
            <Header/>
            <div className="innerPageBanner" id="PrivacyCondition">
                <img src={InnerBanner} alt='Inner Page Banner'/>
                <h2>Privacy Policy</h2>
            </div>

            <section className='TermsConditionsSec'>
                <div className="container">
                    <div className="innerContainer">
                        <p>Realm of Royals is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.realmofroyals.com or use our services. Please read this policy carefully. If you do not agree with the terms of this Privacy Policy, do not access or use our services.</p>
                        
                        <h3>1. Information We Collect</h3>
                        <p>We may collect personal information that you voluntarily provide to us when you:</p>
                        <ul>
                            <li>Register for an account</li>
                            <li>Use our services</li>
                            <li>Make transactions or purchases</li>
                            <li>Contact us for support or with inquiries</li>
                        </ul>
                        <p>The types of personal information we may collect include:</p>
                        <ul>
                            <li>Name</li>
                            <li>Email address</li>
                            <li>Payment information (cryptocurrency wallet addresses)</li>
                            <li>User-generated content</li>
                        </ul>
                        <p>We may also automatically collect certain information when you visit our website, such as your IP address, browser type, operating system, referring URLs, and usage information.</p>
                        <h3>2. Use of Information</h3>
                        <p>We may use the information we collect for various purposes, including to:</p>
                        <ul>
                            <li>Provide, operate, and maintain our services</li>
                            <li>Process transactions and send related information, including purchase confirmations and updates</li>
                            <li>Improve our services and develop new features</li>
                            <li>Communicate with you, including responding to your comments, questions, and requests</li>
                            <li>Send you marketing communications and promotional offers, where permitted by law</li>
                            <li>Protect against, identify, and prevent fraud and other unlawful activity</li>
                        </ul>
                        <h3>3. Disclosure of Information</h3>
                        <p>We may disclose your information to third parties in the following circumstances:</p>
                        <ul>
                            <li>With service providers who assist us in providing and operating our services</li>
                            <li>To comply with legal obligations or respond to lawful requests from governmental authorities</li>
                            <li>To protect our rights, property, or safety, or the rights, property, or safety of others</li>
                            
                        </ul>
                        <p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as described in this Privacy Policy.</p>
                        <h3>4. Security of Information</h3>
                        <p>We take reasonable measures to protect your information from unauthorized access, use, alteration, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is completely secure and we cannot guarantee absolute security.</p>
                        <h3>5. Your Choices</h3>
                        <p>You may choose not to provide certain personal information, but this may limit your ability to use certain features of our services. You can update or delete your account information by contacting us at the email address provided below.</p>
                        <h3>6. Children's Privacy</h3>
                        <p>Our services are not directed to children under the age of 18. We do not knowingly collect personal information from children under 18. If we learn that we have collected personal information from a child under 18 without parental consent, we will take steps to delete that information.</p>
                        <h3>7. Changes to This Privacy Policy</h3>
                        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Privacy Policy on our website. Your continued use of our services after the effective date of the revised Privacy Policy constitutes your acceptance of the terms.</p>
                        <h3>8. Contact Us</h3>
                        <p>If you have any questions about this Privacy Policy or our privacy practices, please contact us at:</p>
                        <p>Email: contact@realmofroyals.com</p>
                        <p>By using our website and services, you acknowledge that you have read, understood, and agree to be bound by these Policy.</p>
                    </div>
                </div>
            </section>

            <NewsletterHome/>
            <Footer/>
        </>
	);
};

export default PrivacyPolicy;
