/* eslint-disable jsx-a11y/anchor-is-valid */

import "./custom-sytle.css";
import "../style.css";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

import { useStore } from "../../store/store";
import { toast } from "react-toastify";

import Header from "../../components/Header/Header";
import NewsletterHome from "../../components/NewsletterHome/NewsletterHome";
import Footer from "../../components/Footer/Footer";
import InnerBanner from "../../images/inner-banner.webp";
import {Col, Row} from "react-bootstrap";
import usePhantomWalletService  from '../../services/PhatomWalletService';
import {  sendWalletAmount, checkTrnxInfo} from '../../services/MainService';
import { isLoggedIn } from '../../services/AuthService';
import { returnsPaid, validateReturnsPaid, fetchAllCompletedOrders } from '../../services/OrderService';
import Loader from "../../components/Loader/Loader";

const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const SOLCAN_URL = process.env.REACT_APP_SOLCAN_URL;
const SOLCAN_CLUSTER = process.env.REACT_APP_SOLCAN_CLUSTER;
const APP_TRON_URL = process.env.REACT_APP_TRON_URL;
function CompletedOrders() {
  const { sendReturns } = usePhantomWalletService();
  const etherPrice = useStore((state) => state.etherPrice);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [amountSend, setAmountSend] = useState(new Array(transactions.length).fill(''));
  const handleInputChange = (index, value) => {
   
    setAmountSend(prevState => {
      const newState = [...prevState];
      newState[index] = value;
      return newState;
    });
  };
  // Store the order 
  const handleStoreOrder = async(orderId, amountSend, walletAddress, category) => {
    const filteredAmounts = amountSend.filter(amount => amount !== undefined);
    const firstAmount = filteredAmounts.length > 0 ? filteredAmounts[0] : null;
   
    if (!firstAmount) {
      toast.error('Please Insert Amount');
      return;
    }
    setLoading(true);
    let isLogIn = await isLoggedIn();
    if(isLogIn){
      //if(etherPrice){
        // first validate 
        let flag = await validateReturnsPaid(orderId);
        if(flag){
          try {
           
          let records = await sendWalletAmount(walletAddress, firstAmount);
          if(records.success){
           
             let result = await checkTrnxInfo(records.txId);
             if(result.contractRet == 'SUCCESS'){
                await returnsPaid(orderId, records.txId);
                window.location.reload();
             }else {
               toast.error(result.contractRet);
             }

          }else{
            toast.error(records.contractRet);
          }
        } catch (error) {
             console.error('Error sending transaction Or Insufficient balance:', error);
           }
           finally {
               setLoading(false); 
          }
        }
        // if(flag){
        //   try {
        //     // Pop up phantom, sign transaction & send sol
        //     let records         = await sendReturns(productPrice, walletAddress, category);
        //     let returnTrnx      = records['txId'];
            
        //     // update the order status to paid returns
        //     if(returnTrnx){
        //       await returnsPaid(orderId, returnTrnx); 
        //       // window.location.reload();
        //     }
            
        //   } catch (error) {
        //     console.error('Error sending transaction Or Insufficient balance:', error);
        //   }
        //   finally {
        //     setLoading(false); 
        //   }
        // }
        

        // await storeOrder(productId, referralCode);
      //}else{
        //toast.error("Unable to fetch eth price")
      //}
      // setShow(false);
    }      
  };




  useEffect(() => {
    const fetchData = async () => {
      const trnxData = await fetchAllCompletedOrders();
      setTransactions(trnxData.data);
    };
    if (localStorage.getItem("name")) {
      setName(localStorage.getItem("name"))
      fetchData();
    }
  }, []);





  return (
    <>
      <Header />
      {
           (loading)
           ? 
            <Loader />
          :
          null
        }
      {!name || typeof name === "undefined" ? (
     	null 
      ) : (
        <>
            <div className="innerPageBanner">
              <img src={InnerBanner} alt="Inner Page Banner" />
              <h2>Completed Orders {amountSend}</h2>
            </div><h1>{amountSend}</h1>
			<section className="profileSec">
				<div className="container">
					<Row>
						<Col sm={12} className="column-nine">
							<div className="buySellTable table-responsive">
								<table className="table table-bordered completed-orders">
									<thead>
									<tr>
										
										<th width="500">Date & Time</th>
                    <th>Level</th>
										<th width="500">Package name</th>
                    <th>Claim Amount</th>
										<th>Amount</th>
										{/* <th>Currency</th> */}
										<th>Status</th>
                    <th>User Type</th>
										<th>Return Trnx</th>
									</tr>
									</thead>
									<tbody >
									{transactions.length === 0 ? (
										<p>No transactions found</p>
									) : (
										transactions.map((trnx, index) => (
										<tr key={trnx.id} className=" button_trnx">
											{/* <td className="button_trnx_pad">
												<a href={`${APP_TRON_URL}transaction/${trnx.trnx}`}
													target="_blank"
													rel="noopener noreferrer"
													style={{ display: "block" }}
												>
                          View
													
											</a>
                      <div key={index}>
                        {trnx.children_trnx && trnx.children_trnx.map((child, childIndex) => (
                               
                                <a href= {`${APP_TRON_URL}transaction/${child}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ textDecoration: "none" }}
                                >
                                View
                                </a>
                            
                        ))}
                      </div>
											</td> */}
                      
											<td>{trnx.created_at}</td>
                      <td>{trnx.level}</td>
											<td>{trnx.product_name}</td>
                      <td>{trnx.commission_amount}</td>
											<td>
                      {((trnx.payment_status) && (trnx.payment_status) == 1) ? (
                             
                             <input
                             key={index}
                                  type="number"
                                  placeholder="Enter Send Wallet Address"
                                  value={amountSend[index]}
                                  onChange={(e) => handleInputChange(index, e.target.value)}
                              />
                             
                          ) : (
                              <>{trnx.product_price}</>
                          )}</td>
											{/* <td>USDT</td> */}
											<td>{((trnx.payment_status) && (trnx.payment_status) == 1) ? 'Not Paid' : 'Paid' }</td>
                      <td>{((trnx.category) && (trnx.category) == 2) ? 'Influencer' : 'Self' }</td>
											<td> 
                        {
                          (trnx.return_trnx) ?
                          (
                            <a href={`${APP_TRON_URL}transaction/${trnx.return_trnx}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none" }}
                              >
                                {/* {trnx.return_trnx.length > 25
                                ? `${trnx.return_trnx.slice(0, 25)}...`
                                : trnx.return_trnx} */}
                                View
                            </a>
                          ): 
                          (
                            <Button variant="warning" onClick={() => handleStoreOrder(trnx.id, amountSend, trnx.user_wallet_address, trnx.category)}>
                              Send Returns 
                            </Button>
                          )

                        }
                      </td>
										</tr>
										))
									)}
									</tbody>
								</table>
								</div>
						
					</Col>
					</Row>
				</div>
			</section>
        </>
      )}

      <NewsletterHome />
      <Footer />
    </>
  );
}

export default CompletedOrders;
