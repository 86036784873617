import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./pages";
import About from "./pages/about/about";
import Login from "./pages/login/login";
import ForgetPassword from "./pages/login/ForgetPassword";
import Account from "./pages/account/account";
import ChangePassword from "./pages/account/ChangePassword";
import Contact from "./pages/contact/contact";
import Product from "./pages/product/product";
import Portfolio from "./pages/portfolio/portfolio";
import ConnectWallet from "./pages/ConnectWallet/ConnectWallet";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Works from "./pages/works/works";
import Kingdoms from "./pages/profile/Kingdoms";
import KingdomDetail from "./pages/profile/KingdomDetail";
import CompletedOrders from "./pages/CompletedOrders";
import NotFound from "./pages/notFound/notfound";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import ResetPassword from "./pages/ResetPassword";
import Wallet from "./pages/account/wallet";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './App.css';
import loader from './images/loaders.gif';


function App() {
    const [showLoader, setShowLoader] = useState(true);
    
    
    useEffect(() => {
        const handleLoading = () => {
            setShowLoader(false);
        };

        const handleWindowLoad = () => {
            handleLoading();
        };

        window.addEventListener('load', handleWindowLoad);

        return () => {
            window.removeEventListener('load', handleWindowLoad);
        };
    }, []);

    return (
        <div>
       
            {showLoader && (
                <div className='loader'>
                    <img src={loader} alt='Loader' />
                </div>
            )}
           {!showLoader && (
                <Routes>
                    <Route path="/" element={<Home />} /> 
                    <Route path="/account" element={<Login />}  /> 
                    <Route path="/forget_password" element={<ForgetPassword />}  /> 
                    <Route path="/my_account" element={<Account />}  />
                    <Route path="/change_password" element={<ChangePassword />}  />
                    <Route path="/home" element={<Home />}  />
                    <Route path="/product" element={<Product />}  />
                    <Route path="/portfolio" element={<Portfolio />}  />
                    <Route path="/wallet" element={<Wallet />}  />
                    <Route path="/about" element={<About />}  />
                    <Route path="/contact" element={<Contact />}  />
                    <Route path="/connectwallet" element={<ConnectWallet />}  />
                    <Route path="/termsconditions" element={<TermsConditions />}  />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />}  />
                    <Route path="/works" element={<Works />}  />
                    <Route path="/kingdoms" element={<Kingdoms />}  />
                    <Route path="/kingdomdetail/:orderId" element={<KingdomDetail />}  />
                    <Route path="/completedOrders" element={<CompletedOrders />}  />
                    <Route path="/verify-email/:id/:hash" element={<VerifyEmail />}  />
                    <Route path="/reset-password/:token" element={<ResetPassword />}  />
                    <Route path="*" element={<NotFound />} /> 
                </Routes>
            )}
        </div>
    );
}

function AppWrapper() {
    return (
        <BrowserRouter>
            <App />
        </BrowserRouter>
    );
}

export default AppWrapper;