

import { toast } from "react-toastify";
import { Button } from 'react-bootstrap';
import { useStore } from '../store/store';
import React,{ useEffect, useState } from 'react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { Connection, SystemProgram, Transaction, PublicKey } from '@solana/web3.js';
import BN from 'bn.js'; // Import bn.js for handling large numbers
import * as buffer from "buffer";

const APP_CLUSTER_API = process.env.REACT_APP_SOLCAN_CLUSTER_API;
const TO_ADDRESS = process.env.REACT_APP_TO_ADDRESS;
window.Buffer = buffer.Buffer;
const connection = new Connection(APP_CLUSTER_API);

 
const usePhantomWalletService  = () => {

    const [wallet, setWallet]   = useState(null);

    const walletStatus          = useStore((state) => state.walletStatus);
    const updateWalletStatus    = useStore((state) => state.setWalletStatus);

    const phantomWallet          = useStore((state) => state.phantomWallet);
    const updatePhantomWallet    = useStore((state) => state.setPhantomWallet);

    const defaultAccount        = useStore((state) => state.defaultAccount);
    const updateDefaultAccount  = useStore((state) => state.setDefaultAccount)
  


    useEffect(() => {
        const savedWalletStatus = localStorage.getItem('walletStatus');
        if (savedWalletStatus === 'connected') {
           // setNetwork();
        }
    }, []);

    useEffect(() => {
        const savedWalletStatus = localStorage.getItem('walletStatus');
        if (wallet && savedWalletStatus === 'connected') {
            wallet.connect().then(() => {
                updateWalletStatus(wallet.connected);
                updateDefaultAccount(wallet.publicKey);
            });
        }
    }, [wallet]);

    const setNetwork = async () => {
        
        const phantomVallet = new PhantomWalletAdapter({
            network: WalletAdapterNetwork.Devnet // Or 'mainnet-beta' for the Solana mainnet
        });
        setWallet(phantomVallet);
        updatePhantomWallet(phantomVallet);
    }

    const handleConnect = async () => {
     
        if (!wallet) {
           //alert("Wallet is not available. Please install or connect Phantom wallet.");
            toast.error('Wallet is not available. Please install or connect Phantom wallet.');
            return;
        }
    
        if (!walletStatus) {
            try {
                await wallet.connect();
                updateWalletStatus(wallet.connected);
                updateDefaultAccount(wallet.publicKey);
                localStorage.setItem('walletStatus', 'connected');
            } catch (error) {
                
                toast.error("Connect Your Phantom Wallet ");
            }
        } else {
            try {
                await wallet.disconnect();
                updateWalletStatus(wallet.connected);
                updateDefaultAccount(null);
                localStorage.removeItem('walletStatus');
            } catch (error) {
               
                toast.error("Failed to disconnect wallet. Please try again.");
            }
        }
    };
    

    // const handleConnect = async () => {
    //     if (wallet) {
    //         if (!walletStatus) {
    //             await wallet.connect();
    //             updateWalletStatus(wallet.connected);
    //             updateDefaultAccount(wallet.publicKey);
    //             localStorage.setItem('walletStatus', 'connected');
    //         } else {
    //             await wallet.disconnect();
    //             updateWalletStatus(wallet.connected);
    //             updateDefaultAccount(null);
    //             localStorage.removeItem('walletStatus');
    //         }
    //     }
    // };

    const convertUsdtToSol = async (productUsdtAmount) => {
        const response = await fetch('https://api.binance.com/api/v3/ticker/price?symbol=SOLUSDT');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const solPrice = parseFloat(data.price); // Convert string to number
        // Conversion calculation
        const conversionRate = 1000000000 / solPrice;
        const lamports = new BN(productUsdtAmount).mul(new BN(conversionRate));
        return lamports;
    };



    const sendSol = async (productUsdtAmount) => {
        // console.log("sendsol")
        if (!phantomWallet.connected) {
            
            toast.error('Wallet not connected');
            return false;
        }
        
     
        const fromPublicKey = phantomWallet.publicKey;
        // console.log("fromPublicKey", fromPublicKey)
        const toPublicKey = new PublicKey(TO_ADDRESS);
        // const lamports = new BN(0.5).mul(new BN(1000000000)); // 1 SOL = 1,000,000,000 lamports
        // const lamports = new BN('20000000'); // 0.02
        // const lamports = new BN('500000000');  // 0.5
        const lamports = await convertUsdtToSol(productUsdtAmount);
    
        
        try {
            // Fetch the recent blockhash
            const blockhashObj = await connection.getRecentBlockhash();
            const blockhash = blockhashObj.blockhash;
    
            // Create the transaction
            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: fromPublicKey,
                    toPubkey: toPublicKey,
                    lamports: lamports,
                })
            );
    
            // Sign the transaction
            transaction.recentBlockhash = blockhash;
            transaction.feePayer = fromPublicKey; // Set the fee payer
    
            const signature = await phantomWallet.signTransaction(transaction);
    
            // Send the signed transaction
            const txId = await connection.sendRawTransaction(signature.serialize());
            console.log('Transaction sent:', txId);

            const records            = {};
            records['txId']          = txId;
            records['walletAddress'] = fromPublicKey;


            return records;

        } catch (error) {
            toast.error('Failed to send transaction:', error);

            // console.error('Failed to send transaction:', error);
            // Handle error when user rejects the transaction
            if (error.message === 'User rejected the request') {
                toast.error('User rejected the transaction');

                // console.log('User rejected the transaction');
            }
            return false;
        }
    };

    const sendReturns = async (productUsdtAmount, toWallet, category = 1) => {
        // console.log("sendsol")
        // let amount = 0.005;
        if (!phantomWallet.connected) {
            toast.error('Wallet not connected');
            return false;
        }
    
        const fromPublicKey = phantomWallet.publicKey;
        // console.log("fromPublicKey", fromPublicKey)
        const toPublicKey = new PublicKey(toWallet);
        // const lamports = new BN(0.5).mul(new BN(1000000000)); // 1 SOL = 1,000,000,000 lamports

        if(category == 2){
            productUsdtAmount = productUsdtAmount * 1; // if product is non-purchased, send x1 amount
        }else{
            productUsdtAmount = productUsdtAmount * 2; // if product is purchased, send x2 amount
        }
        // const amt = new BN(productUsdtAmount).mul(new BN(1000000000));
        // const lamports = new BN('20000000'); // 0.02
        // const lamports = amt;  // 0.5
        const lamports = await convertUsdtToSol(productUsdtAmount);

    
        
        try {
            // Fetch the recent blockhash
            const blockhashObj = await connection.getRecentBlockhash();
            const blockhash = blockhashObj.blockhash;
    
            // Create the transaction
            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: fromPublicKey,
                    toPubkey: toPublicKey,
                    lamports: lamports,
                })
            );
    
            // Sign the transaction
            transaction.recentBlockhash = blockhash;
            transaction.feePayer = fromPublicKey; // Set the fee payer
    
            const signature = await phantomWallet.signTransaction(transaction);
    
            // Send the signed transaction
            const txId = await connection.sendRawTransaction(signature.serialize());
            console.log('Transaction sent:', txId);

            const records            = {};
            records['txId']          = txId;
            records['walletAddress'] = fromPublicKey;


            return records;

        } catch (error) {
            toast.error('Failed to send transaction:', error);

            // console.error('Failed to send transaction:', error);
            // Handle error when user rejects the transaction
            if (error.message === 'User rejected the request') {
                toast.error('User rejected the transaction');

                // console.log('User rejected the transaction');
            }
            return false;
        }
    };

    return {
        setNetwork,
        convertUsdtToSol,
        handleConnect,
        sendSol,
        sendReturns
    };
};
 
export default usePhantomWalletService ;