
import { toast } from "react-toastify";
const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const fetchEthPriceUsdt = async() => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      };
  
      const response  = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd`, requestOptions);
  
      if (!response.ok) {
        throw new Error('Failed to fetch eth usdt price');
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
      toast.error('Error fetching eth usdt price:', error);
      return null;
    }
  }

  const fetchSolPriceUsdt = async() => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      };
  
      const response  = await fetch(`https://api.binance.com/api/v3/ticker/price?symbol=SOLUSDT`, requestOptions);
  
      if (!response.ok) {
        throw new Error('Failed to fetch eth usdt price');
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
      toast.error('Error fetching eth usdt price:', error);
      return null;
    }
  }
  
  const formatValue = (value, decimals) => {
    const numericValue = parseFloat(value);
    const factor = Math.pow(10, decimals);
    const formattedValue = numericValue / factor;
  
    // Create a NumberFormat instance for thousands separators and decimal formatting
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: decimals,
    });
  
    // Format the number
    let formattedString = formatter.format(formattedValue);
  
    // Remove extra trailing zeros after the decimal point
    if (decimals > 0) {
      formattedString = formattedString.replace(/(\.\d*?[1-9])0+$/, '$1');
    }
  
    return formattedString;
  };

  const checkTrnxInfo = async (traxn_id) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
            transaction_id: traxn_id,
          }),
      };
      const response = await fetch(APP_BASE_URL + "/getTrnxInfo", requestOptions);
      const data = await response.json();
      
      if (data.user.original.ret[0].contractRet) {
        const records            = {};
       
        records['contractRet']          = data.user.original.ret[0].contractRet;
       
        
        return records;
      } 
    } catch (error) {
      toast.error("Error: " + error.message);
      return false;
    } 
};

  const sendWalletAmount = async (toaddress, withdrawamount) => {
  
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
            to: toaddress,
            amount: withdrawamount,
          }),
      };
      const response = await fetch(APP_BASE_URL + "/sendDollar", requestOptions);
      const data = await response.json();
     
      const records            = {};
      if (data.data.detail.result) {
        
        records['success']= true;
        records['txId']          = data.data.detail.txid;
   
        
       
      } else if(data.data.detail.code == 'CONTRACT_VALIDATE_ERROR' || data.data.detail.code == 'SIGERROR'){
        records['success']= false;
        toast.error('We were unable to complete your withdrawal request.');
      }
      else if(data.data.status== false){
          records['success']= false;
          toast.error(data.data.detail);
      }
      return records;
    } catch (error) {
      toast.error("Error: " + error.message);
      return  false;
    } 
};
export { fetchEthPriceUsdt, fetchSolPriceUsdt, formatValue, sendWalletAmount, checkTrnxInfo };