import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./ProductCard.css";
import { toast } from "react-toastify";
import { storeOrder, validateOrder } from '../../services/OrderService';
import web3 from 'web3'
import Modal from "react-bootstrap/Modal";
import { Button } from 'react-bootstrap';
// import { useStore } from '../../store/store';
import CartButton from '../elements/CartButton';
import { isLoggedIn } from '../../services/AuthService';
import { fetchEthPriceUsdt, sendWalletAmount, checkTrnxInfo} from '../../services/MainService';
import { useStore } from '../../store/store';

import usePhantomWalletService  from '../../services/PhatomWalletService';
import Loader from "../../components/Loader/Loader";

import prod1  from "../../images/pro-small-1.png";
import prod2  from "../../images/pro-small-2.png";
import prod3  from "../../images/pro-small-3.png";
const ProductCard = ({ product, isActive, isOrder }) => {
  const WALLET_APP_FROM_ADDRESS = process.env.REACT_APP_FROM_ADDRESS;
  const navigate    = useNavigate();
  const [loading, setLoading] = useState(false);
  const phantomWallet = useStore((state) => state.phantomWallet);
  const { sendSol } = usePhantomWalletService();

  const [show, setShow] = useState(false);
  const [referralCode, setReferralCode] = useState(null);
  const [hasReferralCode, setHasReferralCode] = useState(true);
  
  const [productId, setProductId] = useState(null);
  const [productPrice, setProductPrice] = useState(null);
  const etherPrice = useStore((state) => state.etherPrice);

  const [selectedOption, setSelectedOption] = useState('yes');
  // Store the order 
  const handleStoreOrder = async() => {
    // form.current.submit();  

    let isLogIn = await isLoggedIn();
    if(isLogIn ){
      if(hasReferralCode){
        if(!referralCode){
          toast.error("Please enter referal code");
          return
        }
      }
      
      // console.log("etherPrice",etherPrice);
      // console.log("productPrice",productPrice);
      //if(etherPrice){

        // first validate 
        // the referral code 
        // does this product has less than 3 children 
        await sendTransaction(productPrice);
        // await storeOrder(productId, referralCode);
      //}else{
        //toast.error("Unable to fetch eth price")
      //}
      setShow(false);
    }      
  };

  
  const sendTransaction = async (productPrice) => {
    setLoading(true);
    try {
      let vldt =  await validateOrder(productId, referralCode); 
      if(vldt){
        
         //let records         = await sendWalletAmount(to,productPrice);
         let records = await sendWalletAmount(WALLET_APP_FROM_ADDRESS, productPrice);
         if(records.success){
          
            let result = await checkTrnxInfo(records.txId);
            if(result.contractRet == 'SUCCESS'){
              await storeOrder(productId, referralCode, records.txId,WALLET_APP_FROM_ADDRESS )
            }else {
              toast.error(result.contractRet);
            }
         }
        // if( records['txId'] ) {
        //   await storeOrder(productId, referralCode, records['txId'],records['walletAddress'] ); 
        // }
      }
    } catch (error) {
      toast.error('Error sending transaction:', error);
      // Add code to handle the error
    }finally {
      setLoading(false); 
    }
  };

  const handleClose = () => {
    setShow(false);
    setSelectedOption('yes');
    setHasReferralCode(true);
  };

  const handleModalForm = async (productId, productPrice) => {
    let isLogIn = await isLoggedIn();
        // let ethUsdt = await fetchEthPriceUsdt();


        if(isLogIn){
          setShow(true);
          setProductId(productId);
          setProductPrice(productPrice);
        } 
    // if(!isActive){
    //   toast.error('connect your Phantom wallet.');
    // }else{
      
    // } 
  }

  // store referral code in setter
  const handleReferralCode = (e) => {

    setReferralCode(e.target.value);
  };
 
  const handleRadioChange = (value) => {
   
    setSelectedOption(value);
    if (value === 'no') {
        setHasReferralCode(false);
        setReferralCode(null);
    }
    if (value === 'yes') {
      setHasReferralCode(true);
    }
  };

  // copy referral code to clipboard
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard');
  };

  // routing
  const handleClick  = (orderId) => {
    navigate('/order/'+orderId);
  }


  return (
      <>
      {
           (loading)
           ? 
            <Loader />
          :
          null
      }
        <div className="productBox">  
          <div className="productBoxInner">
            <div className="productBoxImg">
                <img src={product.product_pic} alt="Product" />
            </div>
            <div className="productBoxInfo">
                <h3>{product.product_name}</h3>
                <p>{product.product_detail}</p>
                <div className="priceAndBuy">
                    <span className="productPrice">USDT {product.product_price}</span>
                    {
                    !isOrder? 
                    (
                      <button className="productBuyBtn" onClick={() => handleModalForm(product.product_id, product.product_price)}>
                        Buy Now! 
                      </button>
                    )
                    :
                    null
                  }
                </div>
                <ul className="membersTree">
                  { 
                    product.users.length === 0 ? 
                    (
                      <p>No products found</p>
                    ) : 
                    (
                      product.users.map((user, index) => (
                       
                        <li key={user.id}><img  style={{ width: '45px', height: '45px' }} src={
                          index === 0
                            ? prod1
                            : index === 1
                            ? prod2
                            : index === 2
                            ? prod3
                            : 'default_image_path'
                        } alt="Member One"/></li>
                      ))
                    )
                  }
                </ul>
            </div>
        </div>
            {
              isOrder? 
              (
                <div className='m-2'>
                  <div className="btn-group m-2">
                    <input
                      type="text"
                      value={product.order_code}
                      className='textCopy'
                      disabled
                    />
                    <Button onClick={() => handleCopy(product.order_code)}>Copy</Button>
                  </div>
                  <Button onClick={() => handleClick(product.order_id)}>View Tree</Button>
                </div>
              ) 
              : null 
            }
      </div>
      
      <Modal show={show} onHide={handleClose} className='referralModal modal-dialog-centered'>
        <Modal.Header closeButton>
            <Modal.Title>Do you have referral code?</Modal.Title>
          
        </Modal.Header>
        
        
        <Modal.Body>
                    {/* <div className="radio-buttons">
                        <div className="form-check">
                            <input 
                                className="form-check-input" 
                                type="radio" 
                                id="yes" 
                                name="referral" 
                                value="yes" 
                                onChange={(e) => handleRadioChange(e.target.value)}
                                checked={selectedOption === 'yes'}
                            />
                            <label className="form-check-label" htmlFor="yes">
                                Yes
                            </label>
                        </div>
                        <div className="form-check">
                            <input 
                                className="form-check-input" 
                                type="radio" 
                                id="no" 
                                name="referral" 
                                value="no" 
                                onChange={(e) => handleRadioChange(e.target.value)}
                                checked={selectedOption === 'no'}
                            />
                            <label className="form-check-label" htmlFor="no">
                                No
                            </label>
                        </div>
                    </div> */}

                    {hasReferralCode && (
                        <input 
                            type="text" 
                            className='form-control' 
                            placeholder='Paste your code here!' 
                            onChange={handleReferralCode}
                        />
                    )}
                </Modal.Body>
                
        <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>Cancel</Button> */}
            <Button variant="primary" onClick={handleStoreOrder}>Buy Now</Button>
        </Modal.Footer>

      </Modal>
    </>
  );
};

export default ProductCard;
