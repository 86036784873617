import React from 'react';
import './notfound.css';
import Header from "../../components/Header/Header";
import NewsletterHome from "../../components/NewsletterHome/NewsletterHome";
import Footer from "../../components/Footer/Footer";


const NotFound = () => {
    return (
        <>
            <Header/>
            <div className='notFoundPage'>
                <h1>Not Found</h1>
            </div>
            <NewsletterHome/>
            <Footer/>
        </>
    );
};

export default NotFound;