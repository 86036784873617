// Filename - pages/index.js

import React, { useState }from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import FooterLogo from "../../images/Logo-Final.webp";
// import FacebookIcon from "../../images/fb-icon.webp";
// import InstaIcon from "../../images/instagram-icon.webp";
import TwitterIcon from "../../images/twitter-icon.webp";
import TelegramIcon from "../../images/telegram-icon.webp";
import DiscordIcon from "../../images/youtube.webp";
import Modal from "react-bootstrap/Modal";
import Loader from "../../components/Loader/Loader";
const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const Footer = () => {
    
    const [show, setShow] = useState(false);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [subject, setSubject] = useState(null);
    const [message, setMessage] = useState(null);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    
        const handleClose = () => {
            setShow(false)
        };
        const handleCshow = () => {
            setShow(true)
        };
        const validateEmail = (email) => {
            if (!email) {
              return false;
            }
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            
            if (!emailRegex.test(email)) {
              return false;
            }
            const localPart = email.split('@')[0];
        
            if (email === 'example@example.com' || localPart.includes('example')) {
              return false;
            }
        
            return true;
          };
        const handleReport = async (event) => {
            event.preventDefault();
        
            const errors = {};
           
        
        
            if (!name) {
              errors.name = true;
            }
            
            if (!email) {
              errors.email = true;
            } else if (!validateEmail(email)) {
              errors.email = true;
              toast.error('Please enter a valid email address.');
            }
            if (!subject) {
              errors.subject = true;
            }
            if (!message) {
                errors.message = true;
            }
            
            
            if (Object.keys(errors).length > 0) {
            // Show errors and set red border
            setErrors(errors);
            //toast.error('Highlighted Feilds are required');
            return;
          }
        
            setLoading(true);
            try {
              // console.log("email", email);
              // console.log("password", password);
              const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  name: name,
                  email: email,
                  subject: subject,
                  message: message,
                  
                }),
              };
            const response = await fetch(APP_BASE_URL + "/report-issue", requestOptions);
        
              const data = await response.json();
             if (data.success) {
                handleClose();
                setName(null);
                setEmail(null);
                setSubject(null);
                setMessage(null);
                setErrors({})
                toast.success(data.message);
              } else {
                if (data.data.error) {
                  toast.error(data.data.error);
                } else {
                  let allErrors = "";
                  for (const property in data.data) {
                    allErrors = " " + data.data[property] + ".";
                  }
                  toast.error(allErrors);
                }
              }
            } catch (error) {
              toast.error("Error:", error);
            }finally {
              setLoading(false); 
            }
          };

          const handleNameChange = (e) => {
            setName(e.target.value);
            if (e.target.value) {
                setErrors((prevErrors) => ({ ...prevErrors, name: false }));
            }
        };
    
        const handleEmailChange = (e) => {
            setEmail(e.target.value);
            if (e.target.value && validateEmail(e.target.value)) {
                setErrors((prevErrors) => ({ ...prevErrors, email: false }));
            }
        };
    
        const handleSubjectChange = (e) => {
            setSubject(e.target.value);
            if (e.target.value) {
                setErrors((prevErrors) => ({ ...prevErrors, subject: false }));
            }
        };
    
        const handleMessageChange = (e) => {
            setMessage(e.target.value);
            if (e.target.value) {
                setErrors((prevErrors) => ({ ...prevErrors, message: false }));
            }
        };
	return (
		<>
        {
           (loading)
           ? 
            <Loader />
          :
          null
        }
			<footer className="siteFooter">
                <div className="footerTop">
                    <div className="footerLogo">
                        <Link to="/"><img src={FooterLogo} alt="Footer Logo"/></Link>
                    </div>
                    <div className="footerNav">
                    <ul>
                            <li><Link to="/home">Home</Link></li>
                            <li>
                                <Link
                                to="/home"
                                onClick={() => {
                                    setTimeout(() => {
                                    const productsSection = document.getElementById("products");
                                    if (productsSection) {
                                        window.scrollTo({
                                        top: productsSection.offsetTop,
                                        behavior: "smooth"
                                        });
                                    }
                                    }, 100); // Adjust the delay as needed to ensure correct scrolling
                                }}
                                >
                                Products
                                </Link>
                            </li>
                            <li>
                                 <Link
                                    to="/home"
                                    onClick={() => {
                                        setTimeout(() => {
                                        const productsSection = document.getElementById("HowItWorks");
                                        if (productsSection) {
                                            window.scrollTo({
                                            top: productsSection.offsetTop,
                                            behavior: "smooth"
                                            });
                                        }
                                        }, 100); // Adjust the delay as needed to ensure correct scrolling
                                    }}
                                    >
                                    Who We Are
                                </Link>
                            </li>
                            <li>
                                <Link
                                to="/home"
                                onClick={() => {
                                    setTimeout(() => {
                                    const roadmapSection = document.getElementById("roadmap");
                                    if (roadmapSection) {
                                        window.scrollTo({
                                        top: roadmapSection.offsetTop,
                                        behavior: "smooth"
                                        });
                                    }
                                    }, 100); // Adjust the delay as needed to ensure correct scrolling
                                }}
                                >
                                Roadmap
                                </Link>
                            </li>
                            <li>
                                <Link
                                to="/home"
                                onClick={() => {
                                    setTimeout(() => {
                                    const buysellSection = document.getElementById("OurTeam");
                                    if (buysellSection) {
                                        window.scrollTo({
                                        top: buysellSection.offsetTop,
                                        behavior: "smooth"
                                        });
                                    }
                                    }, 100); // Adjust the delay as needed to ensure correct scrolling
                                }}
                                >
                                Our team
                                </Link>
                            </li>
                            <li><Link to="/works" >user Guide</Link></li>
                            <li class="complianBtn" onClick={handleCshow}>Report an issues</li>
                        </ul>
                    </div>
                    <div className="footerSocials">
                        <ul>
                            
                            <li><Link target="_blank" rel="noopener noreferrer" to="http://twitter.com/realmofroyals"><img src={TwitterIcon} alt="Twitter" /></Link></li>
                            <li><Link target="_blank" rel="noopener noreferrer" to="http://t.me/realmofroyals"><img src={TelegramIcon} alt="Telegram" /></Link></li>
                            <li><Link target="_blank" rel="noopener noreferrer" to="https://www.youtube.com/@RealmOfroyals"><img src={DiscordIcon} alt="Discord" /></Link></li>
                        </ul>
                    </div>
                </div>
                <div className="footerBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <p className="copyrightText">Copyright © 2024. Design & Developed by <Link target="_blank" to="https://blockschainer.com/">Blocks Chainer</Link></p>
                            </div>
                            <div className="col-md-6">
                                <div className="termsAndPolicy">
                                    <ul>
                                        
                                        <li><Link to="/termsconditions"
                                          onClick={() => {
                                            setTimeout(() => {
                                            const buysellSection = document.getElementById("TermsCondition");
                                            if (buysellSection) {
                                                window.scrollTo({
                                                top: buysellSection.offsetTop,
                                                behavior: "smooth"
                                                });
                                            }
                                            }, 100); // Adjust the delay as needed to ensure correct scrolling
                                        }}>Terms & Conditions</Link></li>
                                        <li><Link to="/privacypolicy"
                                         onClick={() => {
                                            setTimeout(() => {
                                            const buysellSection = document.getElementById("PrivacyCondition");
                                            if (buysellSection) {
                                                window.scrollTo({
                                                top: buysellSection.offsetTop,
                                                behavior: "smooth"
                                                });
                                            }
                                            }, 100); // Adjust the delay as needed to ensure correct scrolling
                                        }}>Privacy Policy</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</footer>

             <Modal show={show} onHide={handleClose} className='referralModal complianPopup modal-dialog-centered'>
            <Modal.Header closeButton>
                <Modal.Title>Submit a Complaint</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleReport}>
            <Modal.Body>
           
                <Form.Control
                type="text"
                id="inputName"
                placeholder="Name"
                value={name}
                
                onChange={handleNameChange}
                style={{ borderColor: errors.name ? 'red' : '' }}
                />
           
                <Form.Control
                    type="email"
                    id="inputEmail"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                    style={{ borderColor: errors.email ? 'red' : '' }}
                />
                <Form.Control
                    type="text"
                    id="inputName"
                    placeholder="Subject"
                    value={subject}
                    
                    onChange={handleSubjectChange}
                    style={{ borderColor: errors.subject ? 'red' : '' }}
                />
                 <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter your message"
                    value={message}
                    
                    onChange={handleMessageChange}
                    style={{ borderColor: errors.message ? 'red' : '' }}
                />
            </Modal.Body>
            <Modal.Footer>
                <button type="submit" class="btn btn-primary">Submit</button>
            </Modal.Footer>
            </Form>

          </Modal>
		</>
	);
};

export default Footer;
