import { toast } from "react-toastify";
const APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const fetchProducts = async() => {
  try {
    const response = await fetch(APP_BASE_URL+'/products');
    if (!response.ok) {
      throw new Error('Failed to fetch products');
    }
    const data = await response.json();
    // console.log("data", data);
    return (data);
  } catch (error) {
    toast.error('Error fetching orders:', error);
    // console.error('Error fetching orders:', error);
    return null;
  }
}



async function storeProducts() {

  try {
    const response = await fetch(APP_BASE_URL+'/products');
    if (!response.ok) {
      throw new Error('Failed to fetch products');
    }
    const data = await response.json();
    return (data);
  } catch (error) {
    toast.error('Error fetching orders:', error);
    // console.error('Error fetching orders:', error);
    return null;
  }
}

export { fetchProducts, storeProducts };