import React, { useEffect, useRef } from 'react';

const LogoutTimer = ({ logout }) => {
  const timeoutIdRef = useRef(null);

  const resetTimer = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(() => {
      logout();
      clearTimer();
    }, 60 * 60 * 1000); // 2 minutes
  };

  const clearTimer = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
  };

  const resetOnActivity = () => resetTimer();

  useEffect(() => {
    const events = ['mousedown', 'keydown', 'mousemove', 'touchstart'];
    events.forEach(event => {
      document.addEventListener(event, resetOnActivity);
    });

    resetTimer();

    return () => {
      clearTimer();
      events.forEach(event => {
        document.removeEventListener(event, resetOnActivity);
      });
    };
  }, []);

  return null;
};

export default LogoutTimer;
