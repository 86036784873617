import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import './UserTree.css'; // Import the CSS file
import Loader from "../../components/Loader/Loader";
const testImgSrc = 'http://0.gravatar.com/avatar/06005cd2700c136d09e71838645d36ff?s=69&d=wavatar';

const UserTree = () => {
  const [userId, setUserId] = useState(localStorage.getItem('userId'));
  const [members, setMembers] = useState([]); // Initialize as an empty array
  const [widths, setWidths] = useState({});
  const [loading, setLoading] = useState(false);
  const [approvedStatuses, setApprovedStatuses] = useState({}); 
  const APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const fetchUserTree = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      };
      const response = await fetch(`${APP_BASE_URL}/user-tree/${userId}`, requestOptions);
      const data = await response.json();
      
      if (data.success) {
        setMembers(data.data);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error('Error: ' + error.message);
    }
  };

  // Claim Money reques to admin
  const handleClaim = async (commissionId) => {
    setLoading(true);
    //alert(commissionId);
    try {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify({
              id: commissionId
          }),
        };

      const response = await fetch(APP_BASE_URL + "/updateReqPaymentStatus", requestOptions);
      const data = await response.json();
      
      if (data.success) {
        toast.success(data.message);
        setApprovedStatuses((prevStatuses) => ({
          ...prevStatuses,
          [commissionId]: true,
        }));
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error('Error: ' + error.message);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserTree();
  }, []);

  useEffect(() => {
    if (members.length > 0) {
      const calculateWidths = (parentId) => {
        const children = members.filter((member) => member.parentId === parentId);
        const newWidths = {};
        if (children.length > 0) {
          const width = 100 / children.length;
          children.forEach((child) => {
            newWidths[child.memberId] = width;
            Object.assign(newWidths, calculateWidths(child.memberId));
          });
        }
        return newWidths;
      };

      setWidths(calculateWidths(null));
    }
  }, [members]);

  const buildTree = (parentId, depth = 0) => {
    if (depth > 4) return null;
    return members
      .filter((member) => member.parentId === parentId)
      .map((member) => {
        // Calculate the width with a 2% reduction
        const calculatedWidth = (widths[member.memberId] || 100) - 2;

        return (
          <>
          {
             (loading)
             ? 
              <Loader />
            :
            null
          }
          <div
            className="containers"
            id={`containerFor${member.memberId}`}
            key={member.memberId}
            style={{ width: `${calculatedWidth}%`,marginTop:'35px' }}
          >
            <div className="member">
              {member.memberId}
              <div className="metaInfo">
                {/* <img src={testImgSrc} alt="avatar" />  */}
                <p>{member.name}</p> 
               {member.commission && (
                  <>
                  <p>{member.commission}</p>
                 </>
                 )}
                {member.commission && member.paymentStatus == null && !approvedStatuses[member.commission_id] &&(
                  <>
                    
                    <button onClick={() => handleClaim(member.commission_id)}>Claim</button>
                  </>
                  
                )}
                 <>
                  {(approvedStatuses[member.commission_id] ||  member.paymentStatus == 1) &&(
                    <span>Pending</span>
                  )}
                  {member.paymentStatus == 2 &&(
                    <span>Approved</span>
                  )}
                  </>
              </div>
              {buildTree(member.memberId, depth + 1)}
            </div>
          </div>
          </>
        );
      });
  };

  return <div id="mainContainer">{buildTree(null)}</div>;
};

export default UserTree;
