import React from 'react';
import "./OurTeam.css";

import HowItBg from "../../images/our-team.webp";
import MemberImg1 from "../../images/team1.svg";
import MemberImg2 from "../../images/team2.svg";
import MemberImg3 from "../../images/team3.svg";
import MemberImg4 from "../../images/team4.svg";
import MemberImg5 from "../../images/team5.svg";

const OurTeam = () => {
    return (
       <section className='OurTeam' id='OurTeam'>
            <div className="container">
                <div className="OurTeamContent">
                    <div className="OurTeamHead">
                        <h1>Our team</h1>
                    </div>
                    <div className="OurTeamBoxes">
                        <div className="row">
                            <div className="w-20">
                                <div className="BoxContent">
                                    <img src={MemberImg1} alt='MemberImg'/>
                                    <h3>Santosh</h3>
                                    <p>(Director)</p>
                                </div>
                            </div>
                            <div className=" w-20">
                                <div className="BoxContent">
                                    <img src={MemberImg2} alt='MemberImg'/>
                                    <h3>Sam</h3>
                                    <p>(Sales & Marketing VP)</p>
                                </div>
                            </div>
                            <div className=" w-20">
                                <div className="BoxContent">
                                    <img src={MemberImg3} alt='MemberImg'/>
                                    <h3>Junaid J.</h3>
                                    <p>(Technical Advisor)</p>
                                </div>
                            </div>
                            <div className="w-20">
                                <div className="BoxContent">
                                    <img src={MemberImg4} alt='MemberImg'/>
                                    <h3>Shawn Williams</h3>
                                    <p>(Founder)</p>
                                </div>
                            </div>
                            <div className="w-20">
                                <div className="BoxContent">
                                    <img src={MemberImg5} alt='MemberImg'/>
                                    <h3>amanda</h3>
                                    <p>(Co-founder)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default OurTeam;