import React, {useState} from "react";
import './works.css';

import "../../../node_modules/video-react/dist/video-react.css";
import { Player, ControlBar  } from 'video-react';

import Header from "../../components/Header/Header";
import NewsletterHome from "../../components/NewsletterHome/NewsletterHome";
import Footer from "../../components/Footer/Footer";

import InnerBanner from "../../images/inner-banner.webp";
import VideoOverlay from "../../images/video-overlay.webp";

//import registerVideo from "../../videos/how-to-register.mp4";
//import buildKingdom from "../../videos/build_your_kingdom_rev.mp4";
//import SellKingdom from "../../videos/sell_your_kingdom.mp4";

const Works = () => {
    const [isIframeLoaded, setIsIframeLoaded] = useState(false);
    const handlePosterClick = () => {
        setIsIframeLoaded(true);
    };
	return (
		<>
            <Header/>
            <div className="innerPageBanner">
                <img src={InnerBanner} alt='Inner Page Banner'/>
                <h2>user Guide</h2>
            </div>

            <section className='workSecOne top_space220'>
                <div className="container">
                    <div className='secTitle text-center wrokText'>
                        <h1>How to create an account</h1>
                        <p>Watch this video tutorial to learn how to effortlessly create your account. We’ll guide you through each step of the registration process, from signing up to completing your profile, so you can start using our platform with ease</p>
                            {!isIframeLoaded && (
                                <img
                                    src={VideoOverlay}
                                    alt="Poster"
                                    //style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                                    onClick={handlePosterClick}
                                />
                            )}
                            {isIframeLoaded && (
                                <iframe
                                width="100%"
                                height="690"
                                src="https://www.youtube.com/embed/vb9rkkcXBP8?si=6_BpKKzuzIQOBBDR"
                                frameborder="0"
                                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                            )}
                    </div>
                </div>
            </section>
            <section className='workSecTwo'>
                <div className="container">
                    <div className='secTitle text-center wrokText'>
                        <h1>How to Purchase a Package & Build Your Realm</h1>
                        <p>In this video tutorial, we walk you through the process of purchasing a package and building your own realm. Discover how to select the perfect package, complete your purchase, and start crafting and expanding your virtual Realm. Follow along for a step-by-step guide to create and manage your realm effortlessly.</p>
                        {!isIframeLoaded && (
                            <img
                                src={VideoOverlay}
                                alt="Poster"
                                //style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                                onClick={handlePosterClick}
                            />
                        )}
                        {isIframeLoaded && (
                            <iframe
                            width="100%"
                            height="690"
                            src="https://www.youtube.com/embed/MqSeGtsISBM?si=bM9yfpMiRrxX8NXe"
                            frameborder="0"
                             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                        )}
                    </div>
                </div>
            </section>
            <section className='workSecOne workSecLast'>
                <div className="container">
                    <div className='secTitle text-center wrokText'>
                        <h1>Sell Your Realm</h1>
                        <p>Once you have three users under your rule, you can sell your Realm to us at a 100% profit! For example, if you purchased the Emerald Package for USDT 1000, you can withdraw USDT 2000 to your wallet.</p>
                        {!isIframeLoaded && (
                            <img
                                src={VideoOverlay}
                                alt="Poster"
                                //style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                                onClick={handlePosterClick}
                            />
                        )}
                        {isIframeLoaded && (
                            <iframe
                            width="100%"
                            height="690"
                            src="https://www.youtube.com/embed/pDrVUSz6p88?si=Xm3j9bjBL5UVYxB6"
                            frameborder="0"
                             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                        )}
                    </div>
                </div>
            </section>

            <NewsletterHome/>
            <Footer/>
        </>
	);
};

export default Works;
